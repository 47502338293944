const fiveCharWords = [
  "АБАКА",
  "АББАТ",
  "АБВЕР",
  "АБЗАЦ",
  "АБОРТ",
  "АБРЕК",
  "АБРИС",
  "АБХАЗ",
  "АБЦУГ",
  "АБШИД",
  "АВАНС",
  "АВАРЫ",
  "АВГИТ",
  "АВГУР",
  "АВЕНЮ",
  "АВИЗО",
  "АВОСЬ",
  "АВРАЛ",
  "АВТОЛ",
  "АВТОР",
  "АГАВА",
  "АГАМА",
  "АГАМИ",
  "АГАМЫ",
  "АГЕНТ",
  "АГНАТ",
  "АГНЕЦ",
  "АГРАФ",
  "АГУЛЫ",
  "АДЕПТ",
  "АДРЕС",
  "АДРЯС",
  "АЖГОН",
  "АЗАРТ",
  "АЗИАТ",
  "АЙВАН",
  "АЙМАК",
  "АЙРАН",
  "АЙСОР",
  "АИСТЫ",
  "АКАЖУ",
  "АКАНТ",
  "АКРИЛ",
  "АКСОН",
  "АКТЕР",
  "АКТИВ",
  "АКУЛА",
  "АКУЛЫ",
  "АКЦИЗ",
  "АКЦИЯ",
  "АЛАРМ",
  "АЛЕУТ",
  "АЛИБИ",
  "АЛКАШ",
  "АЛЛАХ",
  "АЛЛЕЯ",
  "АЛЛЮР",
  "АЛМАЗ",
  "АЛТЕЙ",
  "АЛТЫН",
  "АЛЧБА",
  "АЛЫЧА",
  "АЛЬФА",
  "АМБАР",
  "АМБРА",
  "АМБРЕ",
  "АМВОН",
  "АМЕБА",
  "АМЕБЫ",
  "АМИНЬ",
  "АМПЕР",
  "АМПИР",
  "АМУРЫ",
  "АНГАР",
  "АНГЕЛ",
  "АНИОН",
  "АНКЕР",
  "АНОНС",
  "АНТЕЙ",
  "АНТИК",
  "АНТРЕ",
  "АНЧАР",
  "АНШЕФ",
  "АОРТА",
  "АПОРТ",
  "АПРОШ",
  "АРАБЫ",
  "АРБУЗ",
  "АРГОН",
  "АРГУС",
  "АРЕАЛ",
  "АРЕКА",
  "АРЕНА",
  "АРЕСТ",
  "АРИЕЦ",
  "АРКАН",
  "АРМИЯ",
  "АРМЯК",
  "АРХАР",
  "АРХИВ",
  "АРШИН",
  "АСКЕР",
  "АСКЕТ",
  "АСПИД",
  "АСТАТ",
  "АСТМА",
  "АСТРА",
  "АСЦИТ",
  "АТАКА",
  "АТЛАС",
  "АТЛЕТ",
  "АТОЛЛ",
  "АТТИК",
  "АФЕРА",
  "АФИША",
  "АЦТЕК",
  "АЭРОБ",
  "АЭРОН",
  "БАБКА",
  "БАБКИ",
  "БАБЬЕ",
  "БАГАЖ",
  "БАГГИ",
  "БАГЕТ",
  "БАГОР",
  "БАДАН",
  "БАДЬЯ",
  "БАЗАР",
  "БАЗИС",
  "БАЙГА",
  "БАЙДА",
  "БАЙКА",
  "БАКАН",
  "БАКЕН",
  "БАКУН",
  "БАЛДА",
  "БАЛЕТ",
  "БАЛКА",
  "БАЛОК",
  "БАЛЫК",
  "БАНАН",
  "БАНДА",
  "БАНКА",
  "БАНКИ",
  "БАНТУ",
  "БАРАК",
  "БАРАН",
  "БАРЕЖ",
  "БАРЖА",
  "БАРИЙ",
  "БАРИН",
  "БАРИТ",
  "БАРИЧ",
  "БАРКА",
  "БАРМЫ",
  "БАРОН",
  "БАРЫШ",
  "БАСКА",
  "БАСКИ",
  "БАСМА",
  "БАСНИ",
  "БАСНЬ",
  "БАСНЯ",
  "БАСОК",
  "БАСОН",
  "БАТАТ",
  "БАТОГ",
  "БАТОН",
  "БАТУТ",
  "БАТЫР",
  "БАХТА",
  "БАХУС",
  "БАХЧА",
  "БАЧКИ",
  "БАЧОК",
  "БАШКА",
  "БАШНЯ",
  "БЕГУН",
  "БЕДРА",
  "БЕДРО",
  "БЕЗИК",
  "БЕЙКА",
  "БЕКАР",
  "БЕКАС",
  "БЕКОН",
  "БЕЛАЯ",
  "БЕЛЕК",
  "БЕЛЕЦ",
  "БЕЛКА",
  "БЕЛКИ",
  "БЕЛОЕ",
  "БЕЛОК",
  "БЕЛЫЕ",
  "БЕЛЫЙ",
  "БЕЛЬЕ",
  "БЕЛЯК",
  "БЕЛЯШ",
  "БЕРДО",
  "БЕРЕГ",
  "БЕРЕТ",
  "БЕТОН",
  "БИВАК",
  "БИВНИ",
  "БИДОН",
  "БИЗОН",
  "БИЛЕТ",
  "БИЛЛЬ",
  "БИНОМ",
  "БИРЖА",
  "БИРКА",
  "БИРЮК",
  "БИРЮЧ",
  "БИСЕР",
  "БИТВА",
  "БИТКИ",
  "БИТОК",
  "БИТУМ",
  "БИТЫЙ",
  "БИТЬЕ",
  "БИТЮГ",
  "БЛАГА",
  "БЛАГО",
  "БЛАЖЬ",
  "БЛАНК",
  "БЛАТО",
  "БЛЕСК",
  "БЛИЗЬ",
  "БЛИНТ",
  "БЛИНЫ",
  "БЛОХА",
  "БЛУЗА",
  "БЛЮДО",
  "БЛЯХА",
  "БОБЕР",
  "БОБИК",
  "БОБОК",
  "БОГАЧ",
  "БОДЯК",
  "БОЖБА",
  "БОЖОК",
  "БОЙНЯ",
  "БОКАЛ",
  "БОЛИД",
  "БОМБА",
  "БОНЗА",
  "БОНМО",
  "БОННА",
  "БОРДО",
  "БОРЕЙ",
  "БОРЕЦ",
  "БОРОВ",
  "БОРОК",
  "БОРТЬ",
  "БОСЯК",
  "БОТВА",
  "БОТИК",
  "БОЧАГ",
  "БОЧАР",
  "БОЧКА",
  "БОЧКИ",
  "БОЧОК",
  "БРАВО",
  "БРАГА",
  "БРАДА",
  "БРАНЬ",
  "БРАСС",
  "БРЕМЯ",
  "БРЕШЬ",
  "БРИДЖ",
  "БРИКА",
  "БРИЛЬ",
  "БРИТТ",
  "БРОВЬ",
  "БРОНХ",
  "БРОНЯ",
  "БРОШЬ",
  "БРЫЖИ",
  "БРЫЛА",
  "БРЫЛЫ",
  "БРЮКИ",
  "БРЮХО",
  "БУБЕН",
  "БУБНА",
  "БУБНЫ",
  "БУБОН",
  "БУГАЙ",
  "БУГОР",
  "БУДКА",
  "БУДНИ",
  "БУДРА",
  "БУДЯК",
  "БУКАН",
  "БУКВА",
  "БУКЕТ",
  "БУКЛЕ",
  "БУКЛИ",
  "БУКЛЯ",
  "БУКСА",
  "БУЛАТ",
  "БУЛГА",
  "БУЛКА",
  "БУЛЛА",
  "БУРАВ",
  "БУРАК",
  "БУРАН",
  "БУРАТ",
  "БУРДА",
  "БУРКА",
  "БУРКИ",
  "БУРСА",
  "БУРУН",
  "БУРЯТ",
  "БУТАН",
  "БУТОН",
  "БУТСА",
  "БУТСЫ",
  "БУТУЗ",
  "БУФЕР",
  "БУФЕТ",
  "БУХТА",
  "БЫДЛО",
  "БЫЛКА",
  "БЫЛОЕ",
  "БЫЛЬЕ",
  "БЫТИЕ",
  "БЫТЬЕ",
  "БЫЧКИ",
  "БЫЧОК",
  "БЮВАР",
  "БЮВЕТ",
  "БЮКСА",
  "ВАБИК",
  "ВАГОН",
  "ВАЗОН",
  "ВАКСА",
  "ВАЛАХ",
  "ВАЛЕЖ",
  "ВАЛЕК",
  "ВАЛЕР",
  "ВАЛЕТ",
  "ВАЛЕЦ",
  "ВАЛИК",
  "ВАЛКА",
  "ВАЛКИ",
  "ВАЛОК",
  "ВАЛУЙ",
  "ВАЛУН",
  "ВАЛУХ",
  "ВАЛЬС",
  "ВАННА",
  "ВАННЫ",
  "ВАНТА",
  "ВАНТЫ",
  "ВАРАН",
  "ВАРКА",
  "ВАРОК",
  "ВАРЯГ",
  "ВАТИН",
  "ВАТКА",
  "ВАФЛЯ",
  "ВАХТА",
  "ВДОВА",
  "ВЕДРО",
  "ВЕДУН",
  "ВЕЖДА",
  "ВЕЖДЫ",
  "ВЕКША",
  "ВЕЛЮР",
  "ВЕНГР",
  "ВЕНДЫ",
  "ВЕНЕЦ",
  "ВЕНИК",
  "ВЕНОК",
  "ВЕПРЬ",
  "ВЕПСЫ",
  "ВЕРБА",
  "ВЕРВЬ",
  "ВЕРЕД",
  "ВЕРЕС",
  "ВЕРЕЯ",
  "ВЕРКИ",
  "ВЕРФЬ",
  "ВЕРХА",
  "ВЕРХИ",
  "ВЕРША",
  "ВЕСЛО",
  "ВЕСНА",
  "ВЕСОК",
  "ВЕСТЬ",
  "ВЕТВЬ",
  "ВЕТЕР",
  "ВЕТКА",
  "ВЕТЛА",
  "ВЕТРЫ",
  "ВЕЧЕР",
  "ВЕШКА",
  "ВЕЩЕЕ",
  "ВЕЩУН",
  "ВЗБЕГ",
  "ВЗВАР",
  "ВЗВОД",
  "ВЗДОР",
  "ВЗДОХ",
  "ВЗЛЕТ",
  "ВЗЛОМ",
  "ВЗМАХ",
  "ВЗМЕТ",
  "ВЗМЫВ",
  "ВЗНОС",
  "ВЗРЕЗ",
  "ВЗРЫВ",
  "ВЗРЫД",
  "ВЗЫСК",
  "ВИДЕО",
  "ВИДИК",
  "ВИДОК",
  "ВИЗАЖ",
  "ВИЗИР",
  "ВИЗИТ",
  "ВИЛКА",
  "ВИЛЛА",
  "ВИЛОК",
  "ВИНЦО",
  "ВИОЛА",
  "ВИРАЖ",
  "ВИРУС",
  "ВИРША",
  "ВИРШИ",
  "ВИСКИ",
  "ВИСОК",
  "ВИТИЯ",
  "ВИТОК",
  "ВИТЬЕ",
  "ВИХОР",
  "ВИХРЫ",
  "ВИХРЬ",
  "ВИШНЯ",
  "ВКЛАД",
  "ВЛАГА",
  "ВЛАСЫ",
  "ВНУКА",
  "ВНУКИ",
  "ВОБЛА",
  "ВОГУЛ",
  "ВОДКА",
  "ВОЖАК",
  "ВОЖДЬ",
  "ВОЖЖА",
  "ВОЖЖИ",
  "ВОЗИК",
  "ВОЗКА",
  "ВОЗНЯ",
  "ВОЗОК",
  "ВОЙНА",
  "ВОКАЛ",
  "ВОЛАН",
  "ВОЛГА",
  "ВОЛНА",
  "ВОЛНЫ",
  "ВОЛОК",
  "ВОЛОС",
  "ВОЛХВ",
  "ВОЛЬТ",
  "ВОПЛИ",
  "ВОПЛЬ",
  "ВОРОГ",
  "ВОРОН",
  "ВОРОТ",
  "ВОРОХ",
  "ВОРЬЕ",
  "ВОТУМ",
  "ВОТЯК",
  "ВОШКА",
  "ВОЯКА",
  "ВПУСК",
  "ВРАКИ",
  "ВРАЛЬ",
  "ВРАТА",
  "ВРЕМЯ",
  "ВРУША",
  "ВСПЫХ",
  "ВСХОД",
  "ВТОРА",
  "ВУАЛЬ",
  "ВЧЕРА",
  "ВЬЕЗД",
  "ВЫБОЙ",
  "ВЫБОР",
  "ВЫВАЛ",
  "ВЫВЕС",
  "ВЫВИХ",
  "ВЫВОД",
  "ВЫВОЗ",
  "ВЫГАР",
  "ВЫГИБ",
  "ВЫГОН",
  "ВЫГУЛ",
  "ВЫДЕЛ",
  "ВЫДОХ",
  "ВЫДРА",
  "ВЫДУВ",
  "ВЫЕЗД",
  "ВЫЖИГ",
  "ВЫЖИМ",
  "ВЫЗОВ",
  "ВЫКАТ",
  "ВЫКОЛ",
  "ВЫКОС",
  "ВЫКУП",
  "ВЫКУС",
  "ВЫЛАЗ",
  "ВЫЛЕТ",
  "ВЫЛОВ",
  "ВЫЛОМ",
  "ВЫМАХ",
  "ВЫМОР",
  "ВЫНОС",
  "ВЫПАД",
  "ВЫПАЛ",
  "ВЫПАР",
  "ВЫПАС",
  "ВЫПОР",
  "ВЫПОТ",
  "ВЫРЕЗ",
  "ВЫРУБ",
  "ВЫСЕВ",
  "ВЫТЬЕ",
  "ВЫХОД",
  "ВЫЧЕТ",
  "ВЫШКА",
  "ВЬЮГА",
  "ВЯЗКА",
  "ВЯЗОК",
  "ВЯТИЧ",
  "ВЯТКА",
  "ГАВОТ",
  "ГАГАТ",
  "ГАЗИК",
  "ГАЗОК",
  "ГАЗОН",
  "ГАЙКА",
  "ГАЛКА",
  "ГАЛЛЫ",
  "ГАЛОП",
  "ГАЛУН",
  "ГАМАК",
  "ГАММА",
  "ГАММЫ",
  "ГАНЕЦ",
  "ГАНЗА",
  "ГАНКА",
  "ГАНЦЫ",
  "ГАРАЖ",
  "ГАРДА",
  "ГАРЕМ",
  "ГАРУС",
  "ГАУСС",
  "ГАШИШ",
  "ГВАЛТ",
  "ГЕЙША",
  "ГЕЛИЙ",
  "ГЕММА",
  "ГЕНИЙ",
  "ГЕРМА",
  "ГЕРОЙ",
  "ГЕТРА",
  "ГЕТРЫ",
  "ГЕТТО",
  "ГЖЕЛЬ",
  "ГИБКА",
  "ГИДРА",
  "ГИДРЫ",
  "ГИЕНА",
  "ГИЕНЫ",
  "ГИЛЯК",
  "ГИНЕЯ",
  "ГИПЮР",
  "ГИРЛО",
  "ГИТАН",
  "ГИТОВ",
  "ГИЧКА",
  "ГЛАВА",
  "ГЛАВК",
  "ГЛАДЬ",
  "ГЛИНА",
  "ГЛИСТ",
  "ГЛУБЬ",
  "ГЛУШЬ",
  "ГЛЫБА",
  "ГЛЯСЕ",
  "ГНЕЙС",
  "ГНИДА",
  "ГНИЛЬ",
  "ГНОМА",
  "ГНУСЬ",
  "ГОБОЙ",
  "ГОВОР",
  "ГОГОТ",
  "ГОДИК",
  "ГОДОК",
  "ГОЛАЯ",
  "ГОЛЕЦ",
  "ГОЛИК",
  "ГОЛОД",
  "ГОЛОС",
  "ГОЛЫЙ",
  "ГОЛЫШ",
  "ГОЛЬД",
  "ГОЛЬЕ",
  "ГОЛЬФ",
  "ГОЛЯК",
  "ГОМОН",
  "ГОНЕЦ",
  "ГОНКА",
  "ГОНКИ",
  "ГОНОР",
  "ГОПАК",
  "ГОРЕЦ",
  "ГОРКА",
  "ГОРЛО",
  "ГОРНО",
  "ГОРОД",
  "ГОРОХ",
  "ГОРЮН",
  "ГОСТЬ",
  "ГОФРЕ",
  "ГОФРЫ",
  "ГРАВЕ",
  "ГРАММ",
  "ГРАНД",
  "ГРАНЬ",
  "ГРАФА",
  "ГРЕБЬ",
  "ГРЕЗА",
  "ГРЕЗЫ",
  "ГРЕКИ",
  "ГРЕНА",
  "ГРЕЧА",
  "ГРИБЫ",
  "ГРИВА",
  "ГРИДЬ",
  "ГРИЛЬ",
  "ГРИПП",
  "ГРОЗА",
  "ГРОЗД",
  "ГРОСС",
  "ГРОШИ",
  "ГРУДА",
  "ГРУДЬ",
  "ГРУНТ",
  "ГРУША",
  "ГРЫЖА",
  "ГРЯДА",
  "ГРЯЗИ",
  "ГРЯЗЬ",
  "ГУАНО",
  "ГУАШЬ",
  "ГУБАН",
  "ГУБКА",
  "ГУБКИ",
  "ГУГНЯ",
  "ГУДОК",
  "ГУЖИК",
  "ГУЗКА",
  "ГУЗНО",
  "ГУЛЯШ",
  "ГУММА",
  "ГУММИ",
  "ГУМНО",
  "ГУМУС",
  "ГУННЫ",
  "ГУРАН",
  "ГУРДА",
  "ГУРИЯ",
  "ГУСАК",
  "ГУСАР",
  "ГУСЕК",
  "ГУСИТ",
  "ГУСЛИ",
  "ГУЦУЛ",
  "ГЮРЗА",
  "ДАВКА",
  "ДАДАН",
  "ДАМБА",
  "ДАМКА",
  "ДАУРЫ",
  "ДАЧКА",
  "ДВЕРЬ",
  "ДЕБЕТ",
  "ДЕБИЛ",
  "ДЕБИТ",
  "ДЕБОШ",
  "ДЕБРИ",
  "ДЕБЮТ",
  "ДЕВИЗ",
  "ДЕВКА",
  "ДЕВОН",
  "ДЕДКА",
  "ДЕДОК",
  "ДЕЖКА",
  "ДЕИЗМ",
  "ДЕИСТ",
  "ДЕКАН",
  "ДЕКОР",
  "ДЕЛЕЖ",
  "ДЕЛЕЦ",
  "ДЕМОН",
  "ДЕМОС",
  "ДЕНДИ",
  "ДЕНЕК",
  "ДЕРБИ",
  "ДЕРЕН",
  "ДЕРМА",
  "ДЕРТЬ",
  "ДЕСНА",
  "ДЕСТЬ",
  "ДЕТВА",
  "ДЕТКА",
  "ДЕТКИ",
  "ДЕФИС",
  "ДЖИГА",
  "ДЖИНН",
  "ДЗЕТА",
  "ДЗЮДО",
  "ДИАНА",
  "ДИВАН",
  "ДИЕТА",
  "ДИКАЯ",
  "ДИКИЙ",
  "ДИНАР",
  "ДИНГО",
  "ДИЧОК",
  "ДЛАНЬ",
  "ДЛИНА",
  "ДНИЩЕ",
  "ДОБОР",
  "ДОВОД",
  "ДОГМА",
  "ДОГМЫ",
  "ДОЖДЬ",
  "ДОЖИМ",
  "ДОЗОР",
  "ДОЙКА",
  "ДОЙНА",
  "ДОЙРА",
  "ДОКЕР",
  "ДОМЕК",
  "ДОМЕН",
  "ДОМЕР",
  "ДОМНА",
  "ДОМРА",
  "ДОНЕЦ",
  "ДОНКА",
  "ДОННА",
  "ДОНОР",
  "ДОНОС",
  "ДОНЦЕ",
  "ДОНЦЫ",
  "ДОНЬЯ",
  "ДОРКА",
  "ДОСЕВ",
  "ДОСКА",
  "ДОСОЛ",
  "ДОСУГ",
  "ДОСЫЛ",
  "ДОСЬЕ",
  "ДОФИН",
  "ДОХОД",
  "ДОЧКА",
  "ДОШКА",
  "ДРАГА",
  "ДРАЖЕ",
  "ДРАКА",
  "ДРАМА",
  "ДРАНЬ",
  "ДРЕВО",
  "ДРЕЙФ",
  "ДРЕЛЬ",
  "ДРЕМА",
  "ДРЕНА",
  "ДРОБЬ",
  "ДРОВА",
  "ДРОГА",
  "ДРОГИ",
  "ДРОЖЬ",
  "ДРОЗД",
  "ДРОФА",
  "ДРУЗА",
  "ДРУЗЫ",
  "ДРУИД",
  "ДРЯНЬ",
  "ДУБЕЦ",
  "ДУБКА",
  "ДУБЛЬ",
  "ДУБОК",
  "ДУБЬЕ",
  "ДУВАЛ",
  "ДУДАК",
  "ДУДКА",
  "ДУДУК",
  "ДУЖКА",
  "ДУКАТ",
  "ДУМЕЦ",
  "ДУМКА",
  "ДУНЕЦ",
  "ДУНИТ",
  "ДУНСТ",
  "ДУПЛО",
  "ДУРАК",
  "ДУРКА",
  "ДУРРА",
  "ДУРРО",
  "ДУТАР",
  "ДУТИК",
  "ДУТЫШ",
  "ДУТЬЕ",
  "ДУХАН",
  "ДУШКА",
  "ДУШОК",
  "ДУЭЛЬ",
  "ДЩЕРЬ",
  "ДЫЛДА",
  "ДЫМКА",
  "ДЫМОК",
  "ДЫРКА",
  "ДЫШЛО",
  "ДЮБЕК",
  "ДЮКЕР",
  "ДЮШЕС",
  "ДЯТЕЛ",
  "ЕВНУХ",
  "ЕВРЕИ",
  "ЕВРЕЙ",
  "ЕГЕРЬ",
  "ЕГОЗА",
  "ЕЖИХА",
  "ЕЗДКА",
  "ЕЗДОК",
  "ЕЛИНА",
  "ЕМЕЛЯ",
  "ЕРЕСЬ",
  "ЕРНИК",
  "ЕРШИК",
  "ЕСАУЛ",
  "ЕХИДА",
  "ЖАБРЫ",
  "ЖАЖДА",
  "ЖАКАН",
  "ЖАКЕТ",
  "ЖАКОБ",
  "ЖАМКА",
  "ЖАРКА",
  "ЖАРКИ",
  "ЖАРОК",
  "ЖАТВА",
  "ЖАТКА",
  "ЖГУТЫ",
  "ЖЕВОК",
  "ЖЕЛНА",
  "ЖЕЛОБ",
  "ЖЕЛТЬ",
  "ЖЕЛЧЬ",
  "ЖЕНИХ",
  "ЖЕНКА",
  "ЖЕОДА",
  "ЖЕРДЬ",
  "ЖЕРЕХ",
  "ЖЕРЛО",
  "ЖЕСТЬ",
  "ЖЕТОН",
  "ЖИВЕЦ",
  "ЖИВОЕ",
  "ЖИВОЙ",
  "ЖИВОТ",
  "ЖИВЬЕ",
  "ЖИЗНЬ",
  "ЖИЛЕТ",
  "ЖИЛЕЦ",
  "ЖИЛКА",
  "ЖИЛОЕ",
  "ЖИЛЬЕ",
  "ЖИНКА",
  "ЖИРАФ",
  "ЖИРОК",
  "ЖИТИЕ",
  "ЖИТЬЕ",
  "ЖМЕНЯ",
  "ЖНИВА",
  "ЖНИВО",
  "ЖНИЦА",
  "ЖОКЕЙ",
  "ЖРИЦА",
  "ЖУЛАН",
  "ЖУЛИК",
  "ЖУЛЬЕ",
  "ЖУПАН",
  "ЖУПЕЛ",
  "ЖУРКА",
  "ЖУЧКА",
  "ЖУЧОК",
  "ЗАБЕГ",
  "ЗАБОЙ",
  "ЗАБОР",
  "ЗАВАЛ",
  "ЗАВЕТ",
  "ЗАВОД",
  "ЗАВОЗ",
  "ЗАВУЧ",
  "ЗАГАР",
  "ЗАГИБ",
  "ЗАГОН",
  "ЗАГУЛ",
  "ЗАДЕЛ",
  "ЗАДИК",
  "ЗАДОК",
  "ЗАДОР",
  "ЗАЕДА",
  "ЗАЕЗД",
  "ЗАЖИМ",
  "ЗАЖИН",
  "ЗАЖОР",
  "ЗАЗОР",
  "ЗАЗЫВ",
  "ЗАИКА",
  "ЗАЙКА",
  "ЗАЙЦЫ",
  "ЗАКАЗ",
  "ЗАКАЛ",
  "ЗАКАТ",
  "ЗАКОЛ",
  "ЗАКОН",
  "ЗАКУП",
  "ЗАКУТ",
  "ЗАЛЕТ",
  "ЗАЛИВ",
  "ЗАЛИЗ",
  "ЗАЛОГ",
  "ЗАЛОМ",
  "ЗАМАХ",
  "ЗАМЕР",
  "ЗАМЕС",
  "ЗАМЕТ",
  "ЗАМОК",
  "ЗАМОР",
  "ЗАМША",
  "ЗАНОС",
  "ЗАПАД",
  "ЗАПАЛ",
  "ЗАПАС",
  "ЗАПАХ",
  "ЗАПЕВ",
  "ЗАПОЙ",
  "ЗАПОН",
  "ЗАПОР",
  "ЗАРЕЗ",
  "ЗАРОД",
  "ЗАРОК",
  "ЗАРЯД",
  "ЗАСЕВ",
  "ЗАСОВ",
  "ЗАСОЛ",
  "ЗАСОС",
  "ЗАСЫЛ",
  "ЗАТЕИ",
  "ЗАТЕК",
  "ЗАТЕС",
  "ЗАТЕЯ",
  "ЗАТОК",
  "ЗАТОН",
  "ЗАТОР",
  "ЗАУМЬ",
  "ЗАХОД",
  "ЗАЦЕП",
  "ЗАЧЕС",
  "ЗАЧЕТ",
  "ЗАЧИН",
  "ЗАЩИП",
  "ЗВЕНО",
  "ЗВЕНЬ",
  "ЗВЕРЬ",
  "ЗЕБРА",
  "ЗЕВОК",
  "ЗЕЛЬЕ",
  "ЗЕЛЬЦ",
  "ЗЕМЕЦ",
  "ЗЕМЛЯ",
  "ЗЕНИТ",
  "ЗЕНКИ",
  "ЗЕРНО",
  "ЗЕРНЬ",
  "ЗЕФИР",
  "ЗИПУН",
  "ЗЛАКИ",
  "ЗЛАТО",
  "ЗЛОБА",
  "ЗЛЮКА",
  "ЗНАМЯ",
  "ЗНАТЬ",
  "ЗОБИК",
  "ЗОЛКА",
  "ЗОМБИ",
  "ЗРАЗА",
  "ЗРАЗЫ",
  "ЗУБЕЦ",
  "ЗУБИК",
  "ЗУБОК",
  "ЗУБЦЫ",
  "ЗУЛУС",
  "ЗУРНА",
  "ЗЫБКА",
  "ЗЫБУН",
  "ЗЯТЕК",
  "ЗЯТИК",
  "ИБЕРЫ",
  "ИБИСЫ",
  "ИВАСИ",
  "ИВИНА",
  "ИВНЯК",
  "ИВРИТ",
  "ИГИЛЬ",
  "ИГРЕК",
  "ИГРОК",
  "ИГРУН",
  "ИДЕАЛ",
  "ИДИОМ",
  "ИДИОТ",
  "ИЕРЕЙ",
  "ИЖИЦА",
  "ИЗБАЧ",
  "ИЗВЕТ",
  "ИЗВИВ",
  "ИЗВОД",
  "ИЗВОЗ",
  "ИЗГИБ",
  "ИЗГОЙ",
  "ИЗЛЕТ",
  "ИЗЛОМ",
  "ИЗМОЛ",
  "ИЗМОР",
  "ИЗНОС",
  "ИЗЬЯН",
  "ИЗЫСК",
  "ИЗЮБР",
  "ИКОНА",
  "ИКОТА",
  "ИЛЬКА",
  "ИЛЬМА",
  "ИМИДЖ",
  "ИНВАР",
  "ИНГУШ",
  "ИНДИЙ",
  "ИНДУС",
  "ИНДЮК",
  "ИНЖИР",
  "ИНОКА",
  "ИНТИМ",
  "ИНУЛА",
  "ИОНИЯ",
  "ИПРИТ",
  "ИРБИС",
  "ИРМОС",
  "ИСКРА",
  "ИСКУС",
  "ИСЛАМ",
  "ИСПОД",
  "ИСПУГ",
  "ИССОП",
  "ИСТЕЦ",
  "ИСТОД",
  "ИСТОК",
  "ИСХОД",
  "ИУДЕИ",
  "ИУДЕЙ",
  "ИЧИГИ",
  "ИШИАС",
  "КАБАК",
  "КАБАН",
  "КАБИЛ",
  "КАБУЛ",
  "КАВУН",
  "КАГАЛ",
  "КАГАН",
  "КАГАТ",
  "КАГОР",
  "КАДЕТ",
  "КАДИЙ",
  "КАДКА",
  "КАДРЫ",
  "КАДЫК",
  "КАЗАК",
  "КАЗАН",
  "КАЗАХ",
  "КАЗНА",
  "КАЗНЬ",
  "КАЗУС",
  "КАЙЕН",
  "КАЙЛА",
  "КАЙЛО",
  "КАЙМА",
  "КАЙРА",
  "КАКАО",
  "КАЛАН",
  "КАЛАЧ",
  "КАЛИЙ",
  "КАЛИФ",
  "КАЛЛА",
  "КАЛЫМ",
  "КАМАС",
  "КАМЕЯ",
  "КАМИН",
  "КАМКА",
  "КАМСА",
  "КАМУС",
  "КАМЧА",
  "КАМЫШ",
  "КАНАЛ",
  "КАНАТ",
  "КАНВА",
  "КАНОН",
  "КАНОЭ",
  "КАНТЫ",
  "КАНУН",
  "КАНЮК",
  "КАПЕР",
  "КАПЛИ",
  "КАПЛЯ",
  "КАПОК",
  "КАПОР",
  "КАПОТ",
  "КАППА",
  "КАРАТ",
  "КАРГА",
  "КАРДА",
  "КАРЕЛ",
  "КАРИБ",
  "КАРСТ",
  "КАРТА",
  "КАРТЫ",
  "КАСКА",
  "КАССА",
  "КАСТА",
  "КАТАР",
  "КАТЕР",
  "КАТЕТ",
  "КАТОД",
  "КАТОК",
  "КАТЫШ",
  "КАЦАП",
  "КАЧКА",
  "КАШКА",
  "КАШНЕ",
  "КАШПО",
  "КАЩЕЙ",
  "КАЮТА",
  "КВАНТ",
  "КВАРК",
  "КВАРЦ",
  "КВОТА",
  "КЕГЛИ",
  "КЕГЛЬ",
  "КЕГЛЯ",
  "КЕКУР",
  "КЕЛЬТ",
  "КЕЛЬЯ",
  "КЕНАР",
  "КЕНАФ",
  "КЕПКА",
  "КЕФИР",
  "КЕЧУА",
  "КИВЕР",
  "КИВОК",
  "КИЗИЛ",
  "КИЗЯК",
  "КИНЗА",
  "КИНИК",
  "КИОСК",
  "КИПЕР",
  "КИРЗА",
  "КИРКА",
  "КИРХА",
  "КИСЕТ",
  "КИСЕЯ",
  "КИСКА",
  "КИСТА",
  "КИСТЬ",
  "КИФОЗ",
  "КИЧКА",
  "КИШКА",
  "КИШКИ",
  "КЛАДЬ",
  "КЛАКА",
  "КЛАСС",
  "КЛЕКТ",
  "КЛЕРК",
  "КЛЕСТ",
  "КЛЕТЬ",
  "КЛЕЩИ",
  "КЛИКА",
  "КЛИКО",
  "КЛИПС",
  "КЛИШЕ",
  "КЛОУН",
  "КЛУНЯ",
  "КЛУПП",
  "КЛУША",
  "КЛЫКИ",
  "КЛЮКА",
  "КЛЯЧА",
  "КНЕЛИ",
  "КНЕЛЬ",
  "КНЕХТ",
  "КНИГА",
  "КНИКС",
  "КНИЦА",
  "КНЯЗЬ",
  "КОАЛА",
  "КОБЗА",
  "КОБРА",
  "КОБЫЗ",
  "КОВАЧ",
  "КОВЕР",
  "КОВКА",
  "КОГТИ",
  "КОЖАН",
  "КОЖУХ",
  "КОЖЬЕ",
  "КОЗЕЛ",
  "КОЗЛЫ",
  "КОЗНИ",
  "КОЗНЬ",
  "КОЗОН",
  "КОЙКА",
  "КОЙНЕ",
  "КОЙОТ",
  "КОКЕТ",
  "КОККИ",
  "КОКОН",
  "КОКОР",
  "КОКОС",
  "КОЛБА",
  "КОЛЕР",
  "КОЛЕТ",
  "КОЛЕЯ",
  "КОЛИК",
  "КОЛИТ",
  "КОЛКА",
  "КОЛЛИ",
  "КОЛОБ",
  "КОЛОК",
  "КОЛОН",
  "КОЛОС",
  "КОЛУН",
  "КОЛЧА",
  "КОЛЬЕ",
  "КОЛЬТ",
  "КОМАР",
  "КОМИК",
  "КОММИ",
  "КОМОД",
  "КОМОК",
  "КОНЕК",
  "КОНЕЦ",
  "КОНИК",
  "КОНКА",
  "КОНУС",
  "КОНЮХ",
  "КОПАЛ",
  "КОПАЧ",
  "КОПЕР",
  "КОПИР",
  "КОПИЯ",
  "КОПКА",
  "КОПНА",
  "КОПРА",
  "КОПТЫ",
  "КОПУН",
  "КОПЫЛ",
  "КОПЬЕ",
  "КОРАН",
  "КОРДА",
  "КОРЕШ",
  "КОРКА",
  "КОРМА",
  "КОРОБ",
  "КОРЧА",
  "КОРЧИ",
  "КОРЧЬ",
  "КОРЬЕ",
  "КОРЯК",
  "КОСАЧ",
  "КОСАЯ",
  "КОСЕЦ",
  "КОСМЫ",
  "КОСОЙ",
  "КОСОК",
  "КОСТИ",
  "КОСТЬ",
  "КОСЬЕ",
  "КОСЯК",
  "КОТЕЛ",
  "КОТИК",
  "КОТОК",
  "КОФЕЙ",
  "КОФИЙ",
  "КОФТА",
  "КОЧАН",
  "КОЧЕТ",
  "КОЧКА",
  "КОШКА",
  "КОШКИ",
  "КОШМА",
  "КОЩЕЙ",
  "КРАБЫ",
  "КРАГА",
  "КРАГИ",
  "КРАЖА",
  "КРАЛЯ",
  "КРАПП",
  "КРАСА",
  "КРЕДО",
  "КРЕОЛ",
  "КРЕПЬ",
  "КРЕСС",
  "КРЕСТ",
  "КРИЛЬ",
  "КРИЦА",
  "КРОАТ",
  "КРОВЬ",
  "КРОКИ",
  "КРОЛЬ",
  "КРОНА",
  "КРОСС",
  "КРОХА",
  "КРОХИ",
  "КРОШЕ",
  "КРУИЗ",
  "КРУПА",
  "КРУТЬ",
  "КРУЧА",
  "КРУЧЬ",
  "КРЫЛО",
  "КРЫСА",
  "КРЫША",
  "КУБИК",
  "КУБЛО",
  "КУБОК",
  "КУДЛА",
  "КУДЛЫ",
  "КУДРИ",
  "КУЗЕН",
  "КУЗНЯ",
  "КУЗОВ",
  "КУКАН",
  "КУКИШ",
  "КУКЛА",
  "КУКША",
  "КУЛАК",
  "КУЛАН",
  "КУЛЕК",
  "КУЛЕШ",
  "КУЛИК",
  "КУЛИЧ",
  "КУЛОН",
  "КУЛЬТ",
  "КУМАЧ",
  "КУМЖА",
  "КУМИР",
  "КУМЫК",
  "КУМЫС",
  "КУНАК",
  "КУНЬИ",
  "КУПАЖ",
  "КУПЕЦ",
  "КУПЛЯ",
  "КУПОЛ",
  "КУПОН",
  "КУРАЖ",
  "КУРАЙ",
  "КУРДЫ",
  "КУРЕЦ",
  "КУРИЯ",
  "КУРКА",
  "КУРОК",
  "КУРСЫ",
  "КУРЬЯ",
  "КУСОК",
  "КУТАС",
  "КУТЕЖ",
  "КУТОК",
  "КУТУМ",
  "КУТЬЯ",
  "КУХВА",
  "КУХНЯ",
  "КУХТА",
  "КУЧЕР",
  "КУЧКА",
  "КУШАК",
  "КХМЕР",
  "КЮВЕТ",
  "КЮРИЙ",
  "КЮРИН",
  "ЛАБАЗ",
  "ЛАБЕТ",
  "ЛАВАШ",
  "ЛАВКА",
  "ЛАВРА",
  "ЛАВРЫ",
  "ЛАГУН",
  "ЛАДАН",
  "ЛАДЬЯ",
  "ЛАЗЕР",
  "ЛАЗЕЯ",
  "ЛАЗКА",
  "ЛАЗОК",
  "ЛАЙБА",
  "ЛАЙДА",
  "ЛАЙКА",
  "ЛАКЕЙ",
  "ЛАКЦЫ",
  "ЛАМПА",
  "ЛАМУТ",
  "ЛАНДО",
  "ЛАНДЫ",
  "ЛАНКА",
  "ЛАПКА",
  "ЛАПКИ",
  "ЛАПТА",
  "ЛАПША",
  "ЛАРГО",
  "ЛАРЕК",
  "ЛАРЕЦ",
  "ЛАСКА",
  "ЛАССО",
  "ЛАСТЫ",
  "ЛАТКА",
  "ЛАТУК",
  "ЛАТЫШ",
  "ЛАФЕТ",
  "ЛАФИТ",
  "ЛАЧКА",
  "ЛБИНА",
  "ЛБИЩЕ",
  "ЛЕВАК",
  "ЛЕВИТ",
  "ЛЕВША",
  "ЛЕВЫЕ",
  "ЛЕВЫЙ",
  "ЛЕГАТ",
  "ЛЕГАШ",
  "ЛЕДОК",
  "ЛЕЖАК",
  "ЛЕЖКА",
  "ЛЕЙКА",
  "ЛЕМЕШ",
  "ЛЕММА",
  "ЛЕМУР",
  "ЛЕНОК",
  "ЛЕНТА",
  "ЛЕНТО",
  "ЛЕНЦА",
  "ЛЕПЕТ",
  "ЛЕПКА",
  "ЛЕПРА",
  "ЛЕПТА",
  "ЛЕРКА",
  "ЛЕСКА",
  "ЛЕСОК",
  "ЛЕСТЬ",
  "ЛЕТКА",
  "ЛЕТОК",
  "ЛЕТУН",
  "ЛЕШАК",
  "ЛЕШИЙ",
  "ЛЕЩИК",
  "ЛИАНА",
  "ЛИВАН",
  "ЛИВЕР",
  "ЛИДЕР",
  "ЛИЗИН",
  "ЛИЗИС",
  "ЛИЗОЛ",
  "ЛИЗУН",
  "ЛИКЕР",
  "ЛИЛЕЯ",
  "ЛИЛИЯ",
  "ЛИМАН",
  "ЛИМИТ",
  "ЛИМОН",
  "ЛИМФА",
  "ЛИНЕК",
  "ЛИНЗА",
  "ЛИНИЯ",
  "ЛИПЕЦ",
  "ЛИПКА",
  "ЛИПСИ",
  "ЛИРИК",
  "ЛИСКА",
  "ЛИТВА",
  "ЛИТЕР",
  "ЛИТИЙ",
  "ЛИТИЯ",
  "ЛИТКА",
  "ЛИТЬЕ",
  "ЛИХАЧ",
  "ЛИХВА",
  "ЛИЦЕЙ",
  "ЛИШАЙ",
  "ЛИШЕК",
  "ЛОБАН",
  "ЛОББИ",
  "ЛОБИК",
  "ЛОБОК",
  "ЛОВЕЦ",
  "ЛОВЛЯ",
  "ЛОГИК",
  "ЛОДКА",
  "ЛОЖКА",
  "ЛОЖКИ",
  "ЛОЖОК",
  "ЛОКОН",
  "ЛОМИК",
  "ЛОМКА",
  "ЛОНЖА",
  "ЛОПУХ",
  "ЛОТОК",
  "ЛОТОС",
  "ЛОХМЫ",
  "ЛОЦИЯ",
  "ЛОШАК",
  "ЛУБОК",
  "ЛУЖОК",
  "ЛУЗГА",
  "ЛУНИТ",
  "ЛУНКА",
  "ЛУПКА",
  "ЛУЧИК",
  "ЛУЧОК",
  "ЛЫЖНЯ",
  "ЛЫСУН",
  "ЛЫСЫЙ",
  "ЛЫТКА",
  "ЛЫТКИ",
  "ЛЫЧКИ",
  "ЛЫЧКО",
  "ЛЬЯЛО",
  "ЛЮБАЯ",
  "ЛЮБКА",
  "ЛЮБОЕ",
  "ЛЮБОЙ",
  "ЛЮНЕТ",
  "ЛЮПИН",
  "ЛЮПУС",
  "ЛЮРИК",
  "ЛЮТИК",
  "ЛЮТНЯ",
  "ЛЮФФА",
  "ЛЮЧОК",
  "ЛЯГВА",
  "ЛЯЖКА",
  "ЛЯ-ЛЯ",
  "ЛЯМКА",
  "ЛЯПИС",
  "ЛЯРВА",
  "ЛЯРВЫ",
  "ЛЯССЕ",
  "ЛЯШКА",
  "МАВРЫ",
  "МАГИК",
  "МАГИЯ",
  "МАГМА",
  "МАДАМ",
  "МАЕТА",
  "МАЖОР",
  "МАЗАР",
  "МАЗЕР",
  "МАЗИК",
  "МАЗКА",
  "МАЗЛО",
  "МАЗНЯ",
  "МАЗОК",
  "МАЗУР",
  "МАЗУТ",
  "МАЙЕР",
  "МАЙКА",
  "МАЙНА",
  "МАЙОР",
  "МАКАО",
  "МАКЕТ",
  "МАКСА",
  "МАКСИ",
  "МАЛЕК",
  "МАЛЕЦ",
  "МАЛИК",
  "МАЛКА",
  "МАЛОЕ",
  "МАЛЫЙ",
  "МАЛЫШ",
  "МАЛЬЕ",
  "МАЛЯР",
  "МАМАЙ",
  "МАМАН",
  "МАМБА",
  "МАМБО",
  "МАМКА",
  "МАМОН",
  "МАНГО",
  "МАНЕЖ",
  "МАНЕР",
  "МАНИЕ",
  "МАНИЯ",
  "МАНКА",
  "МАНКО",
  "МАННА",
  "МАНОК",
  "МАНСИ",
  "МАНТО",
  "МАНТЫ",
  "МАНУЛ",
  "МАОРИ",
  "МАРАЛ",
  "МАРАН",
  "МАРГО",
  "МАРКА",
  "МАРЛЯ",
  "МАСАИ",
  "МАСКА",
  "МАСЛА",
  "МАСЛО",
  "МАСОН",
  "МАССА",
  "МАССЫ",
  "МАСТЬ",
  "МАТКА",
  "МАТЮГ",
  "МАТЮК",
  "МАФИЯ",
  "МАХАН",
  "МАХРА",
  "МАХРЫ",
  "МАЧТА",
  "МЕДИК",
  "МЕДОК",
  "МЕДЯК",
  "МЕЗГА",
  "МЕЗОН",
  "МЕККА",
  "МЕЛОК",
  "МЕЛОС",
  "МЕНКА",
  "МЕРИН",
  "МЕРКА",
  "МЕССА",
  "МЕСТА",
  "МЕСТО",
  "МЕСТЬ",
  "МЕСЬЕ",
  "МЕСЯЦ",
  "МЕТАН",
  "МЕТИЛ",
  "МЕТИС",
  "МЕТКА",
  "МЕТЛА",
  "МЕТОД",
  "МЕТОЛ",
  "МЕТРО",
  "МЕЦЦО",
  "МЕЧТА",
  "МЕЧТЫ",
  "МЕШОК",
  "МИГАЧ",
  "МИДИЯ",
  "МИЗЕР",
  "МИКСТ",
  "МИЛАЯ",
  "МИЛКА",
  "МИЛОЕ",
  "МИЛОК",
  "МИЛЫЙ",
  "МИЛЬТ",
  "МИМИК",
  "МИНЕР",
  "МИНОР",
  "МИНУС",
  "МИОМА",
  "МИРАБ",
  "МИРАЖ",
  "МИРЗА",
  "МИРОК",
  "МИРОН",
  "МИРРА",
  "МИРТА",
  "МИСКА",
  "МИТОЗ",
  "МИТРА",
  "МИШКА",
  "МИШУК",
  "МЛЕКО",
  "МОГАР",
  "МОГОЛ",
  "МОДИЙ",
  "МОДУС",
  "МОЗГИ",
  "МОЙВА",
  "МОЙКА",
  "МОЙРА",
  "МОККО",
  "МОКОЙ",
  "МОКША",
  "МОЛВА",
  "МОЛВЬ",
  "МОЛКА",
  "МОЛОТ",
  "МОЛОХ",
  "МОЛЯН",
  "МОЛЯР",
  "МОНАХ",
  "МОПЕД",
  "МОРДА",
  "МОРОЗ",
  "МОРОК",
  "МОРЦО",
  "МОРЯК",
  "МОСОЛ",
  "МОСЬЕ",
  "МОТЕТ",
  "МОТИВ",
  "МОТКА",
  "МОТНЯ",
  "МОТОК",
  "МОТОР",
  "МОТТО",
  "МОХЕР",
  "МОХНА",
  "МОХНЫ",
  "МОЧКА",
  "МОШКА",
  "МОШНА",
  "МРАЗЬ",
  "МУАРЕ",
  "МУЖИК",
  "МУЗГА",
  "МУЗЕЙ",
  "МУЛАТ",
  "МУЛЛА",
  "МУЛЯЖ",
  "МУМИЕ",
  "МУМИЯ",
  "МУРАШ",
  "МУРЗА",
  "МУРИН",
  "МУРЛО",
  "МУРЬЯ",
  "МУСОР",
  "МУТОН",
  "МУФТА",
  "МУФТИ",
  "МУЦИН",
  "МУЧКА",
  "МУШАР",
  "МУШКА",
  "МЫМРА",
  "МЫСИК",
  "МЫСЛИ",
  "МЫСЛЬ",
  "МЫСОК",
  "МЫТЬЕ",
  "МЫЧКА",
  "МЫШЕЙ",
  "МЫШКА",
  "МЫШЦА",
  "МЭРИЯ",
  "МЮЗЕТ",
  "МЮРИД",
  "МЯКИШ",
  "МЯЛКА",
  "МЯМЛЯ",
  "МЯНДА",
  "МЯСЦО",
  "МЯТЕЖ",
  "МЯТЬЕ",
  "НАБАТ",
  "НАБЕГ",
  "НАБОБ",
  "НАБОР",
  "НАВАЛ",
  "НАВАР",
  "НАВЕС",
  "НАВЕТ",
  "НАВИС",
  "НАВОЗ",
  "НАВОЙ",
  "НАВЫК",
  "НАГАН",
  "НАГАР",
  "НАГИБ",
  "НАГОН",
  "НАГУЛ",
  "НАДЕЛ",
  "НАДИР",
  "НАДОЙ",
  "НАДУВ",
  "НАЕЗД",
  "НАЖИГ",
  "НАЖИМ",
  "НАЖИН",
  "НАЗЕМ",
  "НАКАЗ",
  "НАКАЛ",
  "НАКАТ",
  "НАКОЛ",
  "НАКРА",
  "НАКРЫ",
  "НАЛЕП",
  "НАЛЕТ",
  "НАЛИВ",
  "НАЛИМ",
  "НАЛОГ",
  "НАЛОЙ",
  "НАМАЗ",
  "НАМЕК",
  "НАМЕТ",
  "НАМИН",
  "НАМОЛ",
  "НАМЫВ",
  "НАНАИ",
  "НАНДУ",
  "НАНКА",
  "НАНОС",
  "НАПЕВ",
  "НАПОЙ",
  "НАПОР",
  "НАРЕЗ",
  "НАРОД",
  "НАРТА",
  "НАРТЫ",
  "НАРЫВ",
  "НАРЯД",
  "НАСАД",
  "НАСОС",
  "НАСЫП",
  "НАТЕК",
  "НАТЕС",
  "НАТЯГ",
  "НАУКА",
  "НАФТА",
  "НАХАЛ",
  "НАХОД",
  "НАЦИЯ",
  "НАЧЕС",
  "НАЧЕТ",
  "НАЧИН",
  "НАЯДА",
  "НЕВЕР",
  "НЕВОД",
  "НЕГРЫ",
  "НЕГУС",
  "НЕДРА",
  "НЕДУГ",
  "НЕМАЯ",
  "НЕМЕЦ",
  "НЕМКА",
  "НЕМОЙ",
  "НЕМЦЫ",
  "НЕНЕЦ",
  "НЕНКА",
  "НЕНЦЫ",
  "НЕРВЫ",
  "НЕРКА",
  "НЕРПА",
  "НЕТЯГ",
  "НЕФТЬ",
  "НЕЧЕТ",
  "НИВКА",
  "НИВХИ",
  "НИЗКА",
  "НИЗОК",
  "НИКТО",
  "НИЛОТ",
  "НИМФА",
  "НИТКА",
  "НИЧТО",
  "НИЧЬЯ",
  "НИЩАЯ",
  "НИЩИЙ",
  "НОВИК",
  "НОВОЕ",
  "НОГАИ",
  "НОЖИК",
  "НОЖКА",
  "НОЖНЫ",
  "НОЙОН",
  "НОЛИК",
  "НОМАД",
  "НОМЕР",
  "НОНЕТ",
  "НОРИЯ",
  "НОРКА",
  "НОРМА",
  "НОРОВ",
  "НОСАЧ",
  "НОСИК",
  "НОСКА",
  "НОСКИ",
  "НОСОК",
  "НОТИС",
  "НОТКА",
  "НОЧКА",
  "НРАВЫ",
  "НУДГА",
  "НУЖДА",
  "НУКЕР",
  "НУЛИК",
  "НУТРО",
  "НЫРЕЦ",
  "НЫРОК",
  "НЫТИК",
  "НЫТЬЕ",
  "НЮАНС",
  "ОАЗИС",
  "ОБВАЛ",
  "ОБВЕС",
  "ОБВОД",
  "ОБВОЗ",
  "ОБГОН",
  "ОБДЕЛ",
  "ОБДИР",
  "ОБДУВ",
  "ОБЖИГ",
  "ОБЖИМ",
  "ОБЖИН",
  "ОБЖОГ",
  "ОБЗОР",
  "ОБИДА",
  "ОБКАТ",
  "ОБКОМ",
  "ОБКОС",
  "ОБЛЕТ",
  "ОБЛИК",
  "ОБЛОВ",
  "ОБЛОГ",
  "ОБЛОМ",
  "ОБМАН",
  "ОБМЕН",
  "ОБМЕР",
  "ОБМЕТ",
  "ОБМИН",
  "ОБМОЛ",
  "ОБМЫВ",
  "ОБНОС",
  "ОБОДЬ",
  "ОБРАЗ",
  "ОБРАТ",
  "ОБРЕЗ",
  "ОБРОК",
  "ОБРУБ",
  "ОБРУЧ",
  "ОБРЫВ",
  "ОБРЯД",
  "ОБСЕВ",
  "ОБУВЬ",
  "ОБУЗА",
  "ОБХОД",
  "ОБЧЕТ",
  "ОБЩЕЕ",
  "ОБЬЕМ",
  "ОБЫСК",
  "ОВОЩИ",
  "ОВРАГ",
  "ОВСЕЦ",
  "ОВСЮГ",
  "ОВЧАР",
  "ОГОНЬ",
  "ОГРЕХ",
  "ОДЕЖА",
  "ОДУЛЫ",
  "ОДУРЬ",
  "ОЖИНА",
  "ОЗЕРО",
  "ОЗИМЬ",
  "ОЗНОБ",
  "ОЙРОТ",
  "ОКЕАН",
  "ОКИСЬ",
  "ОКИЯН",
  "ОКЛАД",
  "ОКЛИК",
  "ОКОВЫ",
  "ОКОЕМ",
  "ОКРАС",
  "ОКРИК",
  "ОКРОЛ",
  "ОКРУГ",
  "ОКТЕТ",
  "ОКУНЬ",
  "ОЛЕИН",
  "ОЛЕНЬ",
  "ОЛЕУМ",
  "ОЛИВА",
  "ОЛИМП",
  "ОЛИФА",
  "ОЛОВО",
  "ОЛЬХА",
  "ОМЕГА",
  "ОМЕЛА",
  "ОМЛЕТ",
  "ОМУЛЬ",
  "ОНАГР",
  "ОНИКС",
  "ОНУЧА",
  "ОПАЛА",
  "ОПАРА",
  "ОПЕКА",
  "ОПЕРА",
  "ОПИСЬ",
  "ОПИУМ",
  "ОПЛОТ",
  "ОПЛЫВ",
  "ОПОЕК",
  "ОПОКА",
  "ОПОРА",
  "ОПРОС",
  "ОПТИК",
  "ОРАВА",
  "ОРАЛА",
  "ОРАЛО",
  "ОРАРЬ",
  "ОРГАН",
  "ОРГИЯ",
  "ОРДЕН",
  "ОРДЕР",
  "ОРЕОЛ",
  "ОРЖАД",
  "ОРКАН",
  "ОРЛАН",
  "ОРЛЕЦ",
  "ОРЛИК",
  "ОРЛЯК",
  "ОРОКИ",
  "ОРОЧИ",
  "ОРШАД",
  "ОСАДА",
  "ОСЕНЬ",
  "ОСЕТР",
  "ОСИНА",
  "ОСКАЛ",
  "ОСЛИК",
  "ОСЛЯК",
  "ОСМАН",
  "ОСМИЙ",
  "ОСМОЛ",
  "ОСМОС",
  "ОСОБА",
  "ОСОБЬ",
  "ОСОЕД",
  "ОСОКА",
  "ОСТИТ",
  "ОСТОВ",
  "ОСТЮК",
  "ОСТЯК",
  "ОСЫПЬ",
  "ОТАВА",
  "ОТАРА",
  "ОТБИВ",
  "ОТБОЙ",
  "ОТБОР",
  "ОТВАЛ",
  "ОТВАР",
  "ОТВЕС",
  "ОТВЕТ",
  "ОТВОД",
  "ОТВОЗ",
  "ОТГИБ",
  "ОТГОН",
  "ОТГУЛ",
  "ОТДЕЛ",
  "ОТДУХ",
  "ОТДЫХ",
  "ОТЕЛЬ",
  "ОТЖИГ",
  "ОТЖИМ",
  "ОТЗОЛ",
  "ОТЗЫВ",
  "ОТКАЗ",
  "ОТКАТ",
  "ОТКОЛ",
  "ОТКОС",
  "ОТКУП",
  "ОТКУС",
  "ОТЛЕТ",
  "ОТЛИВ",
  "ОТЛОВ",
  "ОТЛОГ",
  "ОТЛУП",
  "ОТМАХ",
  "ОТМОЛ",
  "ОТНОС",
  "ОТПАД",
  "ОТПАЛ",
  "ОТПОР",
  "ОТРЕЗ",
  "ОТРОГ",
  "ОТРОК",
  "ОТРУБ",
  "ОТРЫВ",
  "ОТРЯД",
  "ОТСЕВ",
  "ОТСЕК",
  "ОТСОС",
  "ОТТОК",
  "ОТХОД",
  "ОТЦЕП",
  "ОТЧАЛ",
  "ОТЧЕТ",
  "ОТЧИМ",
  "ОТЬЕМ",
  "ОФЕНЯ",
  "ОФОРТ",
  "ОФСЕТ",
  "ОХВАТ",
  "ОХОТА",
  "ОЧЕРК",
  "ОЧЕСЫ",
  "ОШЕЕК",
  "ОШКУЙ",
  "ОЩУПЬ",
  "ПАДЕЖ",
  "ПАДОГ",
  "ПАДУБ",
  "ПАДУН",
  "ПАЙВА",
  "ПАЙЗА",
  "ПАЙКА",
  "ПАКЕТ",
  "ПАКЛЯ",
  "ПАЛАС",
  "ПАЛАЧ",
  "ПАЛАШ",
  "ПАЛЕХ",
  "ПАЛЕЦ",
  "ПАЛЕЯ",
  "ПАЛИЯ",
  "ПАЛКА",
  "ПАЛКИ",
  "ПАЛУБ",
  "ПАМПА",
  "ПАНАШ",
  "ПАНЕР",
  "ПАННА",
  "ПАННО",
  "ПАНТЫ",
  "ПАНЬЕ",
  "ПАНЬЯ",
  "ПАПАХ",
  "ПАПЕЖ",
  "ПАПКА",
  "ПАРАД",
  "ПАРАФ",
  "ПАРЕЗ",
  "ПАРИИ",
  "ПАРИК",
  "ПАРИЯ",
  "ПАРКА",
  "ПАРКИ",
  "ПАРНЯ",
  "ПАРОК",
  "ПАРОМ",
  "ПАРТА",
  "ПАРУН",
  "ПАРУС",
  "ПАРЧА",
  "ПАРША",
  "ПАСМА",
  "ПАСМО",
  "ПАСМЫ",
  "ПАССЫ",
  "ПАСТА",
  "ПАСТЬ",
  "ПАСХА",
  "ПАСЮК",
  "ПАТАН",
  "ПАТЕР",
  "ПАТИО",
  "ПАТЛЫ",
  "ПАТУА",
  "ПАУЗА",
  "ПАФОС",
  "ПАХВИ",
  "ПАХВЫ",
  "ПАХИТ",
  "ПАХТА",
  "ПАЦАН",
  "ПАЧКА",
  "ПАШНЯ",
  "ПАЯЛО",
  "ПЕВЕЦ",
  "ПЕВУН",
  "ПЕГАС",
  "ПЕГАШ",
  "ПЕЙСЫ",
  "ПЕКАН",
  "ПЕКЛО",
  "ПЕМЗА",
  "ПЕНАЛ",
  "ПЕНЕК",
  "ПЕНИЕ",
  "ПЕНИС",
  "ПЕНКА",
  "ПЕННИ",
  "ПЕНЬЕ",
  "ПЕПЕЛ",
  "ПЕПСИ",
  "ПЕРГА",
  "ПЕРЕД",
  "ПЕРЕЦ",
  "ПЕРКА",
  "ПЕРЛО",
  "ПЕРСИ",
  "ПЕРСТ",
  "ПЕРСЫ",
  "ПЕРУН",
  "ПЕРЬЕ",
  "ПЕСЕЦ",
  "ПЕСИК",
  "ПЕСКИ",
  "ПЕСНИ",
  "ПЕСНЬ",
  "ПЕСНЯ",
  "ПЕСОК",
  "ПЕТИТ",
  "ПЕТЛИ",
  "ПЕТЛЯ",
  "ПЕТУХ",
  "ПЕЧКА",
  "ПЕШАЯ",
  "ПЕШЕЦ",
  "ПЕШИЙ",
  "ПЕШКА",
  "ПЕШНЯ",
  "ПЕЩЕР",
  "ПЕЩУР",
  "ПИАЛА",
  "ПИАНО",
  "ПИВКО",
  "ПИГУС",
  "ПИЖМА",
  "ПИЖОН",
  "ПИИТА",
  "ПИКАН",
  "ПИКАП",
  "ПИКЕР",
  "ПИКЕТ",
  "ПИКОН",
  "ПИКТЫ",
  "ПИКША",
  "ПИЛАВ",
  "ПИЛАТ",
  "ПИЛКА",
  "ПИЛОН",
  "ПИЛОТ",
  "ПИМКИ",
  "ПИНИЯ",
  "ПИННА",
  "ПИНОК",
  "ПИНТА",
  "ПИПКА",
  "ПИРАТ",
  "ПИРИТ",
  "ПИРКЕ",
  "ПИРОГ",
  "ПИРОК",
  "ПИРОП",
  "ПИСЕЦ",
  "ПИТОК",
  "ПИТОН",
  "ПИТЬЕ",
  "ПИФИЯ",
  "ПИФОС",
  "ПИХТА",
  "ПИЦЦА",
  "ПИЩИК",
  "ПЛАВЬ",
  "ПЛАМЯ",
  "ПЛАСТ",
  "ПЛАТА",
  "ПЛАТО",
  "ПЛАУН",
  "ПЛАХА",
  "ПЛЕБС",
  "ПЛЕВА",
  "ПЛЕЕР",
  "ПЛЕМЯ",
  "ПЛЕНА",
  "ПЛЕСК",
  "ПЛЕСО",
  "ПЛЕТЬ",
  "ПЛЕЧО",
  "ПЛЕШЬ",
  "ПЛИТА",
  "ПЛИЦА",
  "ПЛОТЬ",
  "ПЛОХО",
  "ПЛЮХА",
  "ПНИЩЕ",
  "ПОБЕГ",
  "ПОБОИ",
  "ПОВАЛ",
  "ПОВАР",
  "ПОВЕТ",
  "ПОВОД",
  "ПОВОЙ",
  "ПОГИБ",
  "ПОГОН",
  "ПОДИЙ",
  "ПОДОГ",
  "ПОДОЙ",
  "ПОДОЛ",
  "ПОЕДЬ",
  "ПОЕЗД",
  "ПОЖАР",
  "ПОЖНЯ",
  "ПОЖОГ",
  "ПОЗЕМ",
  "ПОЗЕР",
  "ПОЗОР",
  "ПОЗЫВ",
  "ПОЗЫК",
  "ПОЙКА",
  "ПОЙЛО",
  "ПОЙМА",
  "ПОИСК",
  "ПОКАЗ",
  "ПОКАТ",
  "ПОКЕР",
  "ПОКОИ",
  "ПОКОЙ",
  "ПОКОС",
  "ПОКУС",
  "ПОЛБА",
  "ПОЛЕТ",
  "ПОЛИВ",
  "ПОЛИП",
  "ПОЛИС",
  "ПОЛКА",
  "ПОЛОГ",
  "ПОЛОЗ",
  "ПОЛОЙ",
  "ПОЛОК",
  "ПОЛОМ",
  "ПОЛОН",
  "ПОЛЮС",
  "ПОЛЯК",
  "ПОЛЯШ",
  "ПОМЕТ",
  "ПОМИН",
  "ПОМОИ",
  "ПОМОЛ",
  "ПОМОР",
  "ПОМПА",
  "ПОНОС",
  "ПОНЧО",
  "ПОНЮХ",
  "ПОПАС",
  "ПОПИК",
  "ПОПКА",
  "ПОПСА",
  "ПОРЕЗ",
  "ПОРЕЙ",
  "ПОРКА",
  "ПОРОГ",
  "ПОРОК",
  "ПОРОХ",
  "ПОРТО",
  "ПОРТЫ",
  "ПОРУБ",
  "ПОРЧА",
  "ПОРЫВ",
  "ПОСАД",
  "ПОСЕВ",
  "ПОСОЛ",
  "ПОСОХ",
  "ПОСУЛ",
  "ПОСЫЛ",
  "ПОТАШ",
  "ПОТЕК",
  "ПОТИР",
  "ПОТОК",
  "ПОТОП",
  "ПОТЯГ",
  "ПОХОД",
  "ПОЧВА",
  "ПОЧЕТ",
  "ПОЧИН",
  "ПОЧКА",
  "ПОЧКИ",
  "ПОЧТА",
  "ПОШИБ",
  "ПОШИВ",
  "ПОЭМА",
  "ПРАВА",
  "ПРАВО",
  "ПРАЩА",
  "ПРЕЛЬ",
  "ПРЕСС",
  "ПРИЕМ",
  "ПРИМА",
  "ПРИНЦ",
  "ПРИОР",
  "ПРИЧТ",
  "ПРИЮТ",
  "ПРОБА",
  "ПРОЕМ",
  "ПРОЗА",
  "ПРОПС",
  "ПРОСО",
  "ПРОУХ",
  "ПРОФИ",
  "ПРУСС",
  "ПРЫСК",
  "ПРЫТЬ",
  "ПРЯДЬ",
  "ПРЯЖА",
  "ПРЯХА",
  "ПСАРЬ",
  "ПСИНА",
  "ПСИЦА",
  "ПСИШЕ",
  "ПТАХА",
  "ПТИЦА",
  "ПУАНТ",
  "ПУГАЧ",
  "ПУДИК",
  "ПУДРА",
  "ПУЗАН",
  "ПУКЛИ",
  "ПУКЛЯ",
  "ПУЛЬС",
  "ПУЛЬТ",
  "ПУНЕЦ",
  "ПУНКТ",
  "ПУПОК",
  "ПУРГА",
  "ПУРИН",
  "ПУРКА",
  "ПУСТО",
  "ПУТЕЦ",
  "ПУТИК",
  "ПУФИК",
  "ПУЧКА",
  "ПУЧОК",
  "ПУШКА",
  "ПУШОК",
  "ПЧЕЛА",
  "ПШЕНО",
  "ПЫЖИК",
  "ПЫРЕЙ",
  "ПЫТКА",
  "ПЫШКА",
  "ПЬЕЗА",
  "ПЬЕРО",
  "ПЬЕСА",
  "ПЯЛКА",
  "ПЯСТЬ",
  "ПЯТАК",
  "ПЯТАЯ",
  "ПЯТКА",
  "ПЯТНА",
  "ПЯТНО",
  "ПЯТОК",
  "ПЯТЫЙ",
  "РАДАР",
  "РАДЖА",
  "РАДИЙ",
  "РАДИО",
  "РАДОН",
  "РАЗИК",
  "РАЗОК",
  "РАЗОР",
  "РАЗУМ",
  "РАИНА",
  "РАЙОН",
  "РАКИЯ",
  "РАКША",
  "РАМКА",
  "РАМКИ",
  "РАМПА",
  "РАНЕТ",
  "РАНЕЦ",
  "РАНКА",
  "РАСТР",
  "РАТАЙ",
  "РАТИН",
  "РАУНД",
  "РАФИЯ",
  "РАХИТ",
  "РАЦЕЯ",
  "РАЦИЯ",
  "РАЧКИ",
  "РАЧОК",
  "РВАНЬ",
  "РВОТА",
  "РДЕСТ",
  "РЕБАБ",
  "РЕБРО",
  "РЕБУС",
  "РЕВУН",
  "РЕГБИ",
  "РЕДАН",
  "РЕДИС",
  "РЕДУТ",
  "РЕЖИМ",
  "РЕЗАК",
  "РЕЗЕЦ",
  "РЕЗКА",
  "РЕЗНЯ",
  "РЕЗОН",
  "РЕЙКА",
  "РЕЛЬС",
  "РЕМЕЗ",
  "РЕМИЗ",
  "РЕМНИ",
  "РЕНЕТ",
  "РЕНИЙ",
  "РЕНТА",
  "РЕПЕЙ",
  "РЕПЕР",
  "РЕПКА",
  "РЕПЬЕ",
  "РЕЧКА",
  "РЕШКА",
  "РЖАВЬ",
  "РЖИЦА",
  "РЖИЩЕ",
  "РИЗКИ",
  "РИКША",
  "РИНИТ",
  "РИПУС",
  "РИСКА",
  "РИТОР",
  "РИФЛИ",
  "РИФМА",
  "РИШТА",
  "РОБОТ",
  "РОВИК",
  "РОВНЯ",
  "РОГАЧ",
  "РОГОЗ",
  "РОДИЙ",
  "РОДИЧ",
  "РОДНЯ",
  "РОЖАК",
  "РОЖОК",
  "РОЖОН",
  "РОЗАН",
  "РОЗГА",
  "РОЗГИ",
  "РОЗНЬ",
  "РОЙБА",
  "РОЙКА",
  "РОКЕР",
  "РОКОТ",
  "РОЛИК",
  "РОМАН",
  "РОМЕЦ",
  "РОМОК",
  "РОМША",
  "РОНДО",
  "РОНЖА",
  "РОПАК",
  "РОПОТ",
  "РОСТР",
  "РОТИК",
  "РОТОК",
  "РОТОР",
  "РОХЛЯ",
  "РОЯЛЬ",
  "РТИЩЕ",
  "РТУТЬ",
  "РУБЕЖ",
  "РУБЕЦ",
  "РУБИН",
  "РУБКА",
  "РУБЛЬ",
  "РУГНЯ",
  "РУДЯК",
  "РУЖЬЕ",
  "РУИНА",
  "РУИНЫ",
  "РУКАВ",
  "РУЛЕТ",
  "РУЛОН",
  "РУМБА",
  "РУМЫН",
  "РУПИЯ",
  "РУПОР",
  "РУСАК",
  "РУСИН",
  "РУСЛО",
  "РУТИЛ",
  "РУЧЕЙ",
  "РУЧКА",
  "РУЧКИ",
  "РЫБАК",
  "РЫБЕЦ",
  "РЫБКА",
  "РЫВОК",
  "РЫЖАК",
  "РЫЖИЙ",
  "РЫЖИК",
  "РЫНДА",
  "РЫНОК",
  "РЫСАК",
  "РЫСЦА",
  "РЫТЬЕ",
  "РЫЧАГ",
  "РЭКЕТ",
  "РЮМКА",
  "РЮШКА",
  "РЯБАЯ",
  "РЯБЕЦ",
  "РЯБИК",
  "РЯБКИ",
  "РЯБОЙ",
  "РЯБОК",
  "РЯДНО",
  "РЯДОК",
  "РЯСКА",
  "РЯСНА",
  "СААМИ",
  "СААМЫ",
  "САБАН",
  "САБЕИ",
  "САБЕЙ",
  "САБЗА",
  "САБЛИ",
  "САБЛЯ",
  "САБУР",
  "САВАН",
  "САГИБ",
  "САДИК",
  "САДНО",
  "САДОК",
  "САЗАН",
  "САЙГА",
  "САЙДА",
  "САЙКА",
  "САЙРА",
  "САКВА",
  "САКЛЯ",
  "САКСЫ",
  "САЛАТ",
  "САЛКИ",
  "САЛМА",
  "САЛОЛ",
  "САЛОН",
  "САЛОП",
  "САЛЮТ",
  "САМАН",
  "САМБА",
  "САМБО",
  "САМЕЦ",
  "САМКА",
  "САМУМ",
  "САНКИ",
  "САПЕР",
  "САПКА",
  "САПОГ",
  "САПУН",
  "САРАЙ",
  "САРЖА",
  "САРЫЧ",
  "САТИН",
  "САТИР",
  "САУНА",
  "САХАР",
  "САЧОК",
  "СБОРА",
  "СБОРЫ",
  "СБРОД",
  "СБРОС",
  "СБРУЯ",
  "СВАНЫ",
  "СВАРА",
  "СВАХА",
  "СВЕЧА",
  "СВИЛЬ",
  "СВИСТ",
  "СВИТА",
  "СВОРА",
  "СВОЯК",
  "СВЯЗА",
  "СВЯЗИ",
  "СВЯЗЬ",
  "СГЛАЗ",
  "СДАЧА",
  "СДВИГ",
  "СДОБА",
  "СЕАНС",
  "СЕВБА",
  "СЕВЕР",
  "СЕВОК",
  "СЕДЛО",
  "СЕДОК",
  "СЕЕВО",
  "СЕЗАМ",
  "СЕЗОН",
  "СЕЙИД",
  "СЕЙША",
  "СЕКАЧ",
  "СЕКТА",
  "СЕЛЕН",
  "СЕЛИН",
  "СЕМГА",
  "СЕМИК",
  "СЕМИТ",
  "СЕМЬЯ",
  "СЕНАТ",
  "СЕНКИ",
  "СЕНЦО",
  "СЕНЦЫ",
  "СЕПИЯ",
  "СЕРАЯ",
  "СЕРБЫ",
  "СЕРИЯ",
  "СЕРКА",
  "СЕРКО",
  "СЕРНА",
  "СЕРОЕ",
  "СЕРСО",
  "СЕРУМ",
  "СЕРЫЙ",
  "СЕТКА",
  "СЕУНЧ",
  "СЕЧКА",
  "СИАЛЬ",
  "СИВАЯ",
  "СИВЕР",
  "СИВКА",
  "СИВКО",
  "СИВУЧ",
  "СИВЫЙ",
  "СИГМА",
  "СИДОР",
  "СИЕНА",
  "СИЖОК",
  "СИЗЯК",
  "СИЛАЧ",
  "СИЛКА",
  "СИЛКИ",
  "СИЛОК",
  "СИЛОН",
  "СИЛОС",
  "СИЛУР",
  "СИЛЬФ",
  "СИМКА",
  "СИНАП",
  "СИНЕЦ",
  "СИНОД",
  "СИНУС",
  "СИНЯК",
  "СИНЯЯ",
  "СИПАЙ",
  "СИРЕН",
  "СИРИН",
  "СИРОП",
  "СИРЫЕ",
  "СИСТР",
  "СИТАР",
  "СИТЕЦ",
  "СИТРО",
  "СИТЦЕ",
  "СИФОН",
  "СКАЛА",
  "СКАЛО",
  "СКАНЬ",
  "СКАРБ",
  "СКАРН",
  "СКАУТ",
  "СКАЧЬ",
  "СКВЕР",
  "СКЕЙТ",
  "СКЕНА",
  "СКЕПТ",
  "СКЕТЧ",
  "СКИРД",
  "СКИФЫ",
  "СКЛАД",
  "СКЛЕП",
  "СКЛИЗ",
  "СКЛОН",
  "СКОБА",
  "СКОПА",
  "СКОРА",
  "СКОРМ",
  "СКОТЧ",
  "СКРАП",
  "СКРЕП",
  "СКРИП",
  "СКУДИ",
  "СКУДО",
  "СКУКА",
  "СКУЛА",
  "СКУЛЫ",
  "СКУНС",
  "СЛАВА",
  "СЛАЙД",
  "СЛАНЬ",
  "СЛЕГА",
  "СЛЕЗА",
  "СЛЕЗЫ",
  "СЛЕНГ",
  "СЛИВА",
  "СЛИЗЬ",
  "СЛОВА",
  "СЛОВО",
  "СЛУГА",
  "СЛУДА",
  "СЛУКА",
  "СЛУХИ",
  "СЛЮДА",
  "СЛЮНА",
  "СЛЮНИ",
  "СМАЗЬ",
  "СМЕНА",
  "СМЕРД",
  "СМЕРЧ",
  "СМЕСЬ",
  "СМЕТА",
  "СМОЛА",
  "СМОЛЫ",
  "СМОЛЬ",
  "СМОТР",
  "СМРАД",
  "СМУТА",
  "СМУТЬ",
  "СМЫСЛ",
  "СНЕДЬ",
  "СНОХА",
  "СНЫТЬ",
  "СОБОР",
  "СОВЕТ",
  "СОВИК",
  "СОВКА",
  "СОВОК",
  "СОГДЫ",
  "СОГРА",
  "СОДОМ",
  "СОЗЫВ",
  "СОЙКА",
  "СОЙМА",
  "СОЙОТ",
  "СОКОЛ",
  "СОЛЕЯ",
  "СОЛКА",
  "СОЛОД",
  "СОЛЯР",
  "СОМИК",
  "СОМЬЕ",
  "СОНЕТ",
  "СОПКА",
  "СОПЛИ",
  "СОПЛО",
  "СОПЛЯ",
  "СОПУН",
  "СОРГО",
  "СОРИТ",
  "СОРОК",
  "СОРОМ",
  "СОРУС",
  "СОСЕД",
  "СОСЕЦ",
  "СОСКА",
  "СОСНА",
  "СОСОК",
  "СОСУД",
  "СОСУН",
  "СОТАЯ",
  "СОТКА",
  "СОТНИ",
  "СОТНЯ",
  "СОТЫЙ",
  "СОФИТ",
  "СОХАЧ",
  "СОШКА",
  "СОШКИ",
  "СПАЗМ",
  "СПЕСЬ",
  "СПИНА",
  "СПИРТ",
  "СПИЦА",
  "СПЛАВ",
  "СПЛИН",
  "СПОРА",
  "СПОРТ",
  "СПРЕЙ",
  "СПРОС",
  "СПРУТ",
  "СПУРТ",
  "СПУСК",
  "СРЕДА",
  "ССОРА",
  "ССУДА",
  "СТАДО",
  "СТАЛЬ",
  "СТАНС",
  "СТАРТ",
  "СТАРЬ",
  "СТАТЬ",
  "СТВОЛ",
  "СТВОР",
  "СТЕГА",
  "СТЕЗЯ",
  "СТЕКА",
  "СТЕЛА",
  "СТЕНА",
  "СТЕНД",
  "СТЕНЫ",
  "СТЕНЬ",
  "СТЕПС",
  "СТЕПЬ",
  "СТИЛО",
  "СТИЛЬ",
  "СТИХИ",
  "СТОИК",
  "СТОЛБ",
  "СТОЛП",
  "СТОПА",
  "СТОПЫ",
  "СТОЯК",
  "СТРАЖ",
  "СТРАЗ",
  "СТРАХ",
  "СТРИЖ",
  "СТРОЙ",
  "СТРОП",
  "СТРУГ",
  "СТРУИ",
  "СТРУП",
  "СТРУЯ",
  "СТУЖА",
  "СТУПЬ",
  "СТЫДЬ",
  "СТЫНЬ",
  "СУАРЕ",
  "СУДАК",
  "СУДКИ",
  "СУДНО",
  "СУДОК",
  "СУДЬЯ",
  "СУЕТА",
  "СУКНА",
  "СУКНО",
  "СУЛЕЯ",
  "СУЛОЙ",
  "СУМКА",
  "СУММА",
  "СУННА",
  "СУПЕР",
  "СУПЕЦ",
  "СУПИН",
  "СУРЖА",
  "СУРИК",
  "СУРНА",
  "СУРОК",
  "СУСАК",
  "СУСЕК",
  "СУСЛО",
  "СУТАЖ",
  "СУТКИ",
  "СУФЛЕ",
  "СУЧКИ",
  "СУЧОК",
  "СУЧЬЕ",
  "СУШКА",
  "СУШКИ",
  "СУЩЕЕ",
  "СФЕРА",
  "СФЕРЫ",
  "СХЕМА",
  "СХИМА",
  "СЦЕНА",
  "СЧЕТЫ",
  "СЬЕЗД",
  "СЫНОК",
  "СЫПЕЦ",
  "СЫРЕЦ",
  "СЫРОК",
  "СЫРТЬ",
  "СЫРЦА",
  "СЫРЬЕ",
  "СЫТАЯ",
  "СЫТЫЙ",
  "СЫЧУГ",
  "СЫЩИК",
  "СЮЖЕТ",
  "СЮИТА",
  "СЯБЕР",
  "СЯЖКИ",
  "СЯЖОК",
  "ТАБАК",
  "ТАБЛО",
  "ТАБОР",
  "ТАБУН",
  "ТАВОТ",
  "ТАВРО",
  "ТАВРЫ",
  "ТАГАН",
  "ТАЗИК",
  "ТАЙГА",
  "ТАЙНА",
  "ТАКОЕ",
  "ТАКСА",
  "ТАКСИ",
  "ТАКЫР",
  "ТАЛЕР",
  "ТАЛЕС",
  "ТАЛИК",
  "ТАЛИЯ",
  "ТАЛОН",
  "ТАЛЫШ",
  "ТАЛЬК",
  "ТАМГА",
  "ТАНГО",
  "ТАНЕЦ",
  "ТАНИН",
  "ТАНОК",
  "ТАНЦЫ",
  "ТАПЕР",
  "ТАПИР",
  "ТАПКА",
  "ТАПКИ",
  "ТАРАН",
  "ТАРИФ",
  "ТАРЫН",
  "ТАСКА",
  "ТАТКА",
  "ТАФТА",
  "ТАФЬЯ",
  "ТАХТА",
  "ТАЦЕТ",
  "ТАЧКА",
  "ТАШКА",
  "ТВАРЬ",
  "ТВИСТ",
  "ТЕАТР",
  "ТЕЗИС",
  "ТЕЗКА",
  "ТЕИЗМ",
  "ТЕИСТ",
  "ТЕКСТ",
  "ТЕЛЕЦ",
  "ТЕЛИК",
  "ТЕЛКА",
  "ТЕЛОК",
  "ТЕМБР",
  "ТЕМКА",
  "ТЕНЕК",
  "ТЕНОР",
  "ТЕПЛО",
  "ТЕРЕМ",
  "ТЕРКА",
  "ТЕРМЫ",
  "ТЕРНО",
  "ТЕСАК",
  "ТЕСКА",
  "ТЕСЛО",
  "ТЕСТО",
  "ТЕСТЬ",
  "ТЕТКА",
  "ТЕУРГ",
  "ТЕЧКА",
  "ТЕШКА",
  "ТИАРА",
  "ТИГРА",
  "ТИМОЛ",
  "ТИПАЖ",
  "ТИПЕЦ",
  "ТИПИК",
  "ТИПУН",
  "ТИРАЖ",
  "ТИРАН",
  "ТИСКИ",
  "ТИТАН",
  "ТИТЛО",
  "ТИТУЛ",
  "ТИФОН",
  "ТИФУС",
  "ТКАНЬ",
  "ТЛЕНЬ",
  "ТОВАР",
  "ТОДЕС",
  "ТОЙОН",
  "ТОКАЙ",
  "ТОЛАЙ",
  "ТОЛКИ",
  "ТОЛПА",
  "ТОЛЩА",
  "ТОМАН",
  "ТОМАТ",
  "ТОМИК",
  "ТОНДО",
  "ТОНИК",
  "ТОННА",
  "ТОНУС",
  "ТОПАЗ",
  "ТОПИК",
  "ТОПКА",
  "ТОПОР",
  "ТОПОТ",
  "ТОРБА",
  "ТОРГИ",
  "ТОРЕЦ",
  "ТОРИЙ",
  "ТОРИТ",
  "ТОРКИ",
  "ТОРОС",
  "ТОСКА",
  "ТОТЕМ",
  "ТОХАР",
  "ТОЧКА",
  "ТРАВА",
  "ТРАВЫ",
  "ТРАКТ",
  "ТРАНС",
  "ТРАНШ",
  "ТРАПП",
  "ТРАСС",
  "ТРАТА",
  "ТРАУР",
  "ТРЕБА",
  "ТРЕЛЬ",
  "ТРЕСК",
  "ТРЕСТ",
  "ТРЕТЬ",
  "ТРЕУХ",
  "ТРЕФА",
  "ТРЕФЫ",
  "ТРИАС",
  "ТРИБА",
  "ТРИЕР",
  "ТРИКО",
  "ТРИОД",
  "ТРОМБ",
  "ТРОМП",
  "ТРОПА",
  "ТРУБА",
  "ТРУДЫ",
  "ТРУСЫ",
  "ТРУХА",
  "ТРЮМО",
  "ТУАЛЬ",
  "ТУБУС",
  "ТУГАЙ",
  "ТУГУН",
  "ТУЗИК",
  "ТУКАН",
  "ТУЛЕС",
  "ТУЛИЙ",
  "ТУЛУЗ",
  "ТУЛУК",
  "ТУЛУН",
  "ТУЛУП",
  "ТУЛЬЯ",
  "ТУМАК",
  "ТУМАН",
  "ТУМБА",
  "ТУНЕЦ",
  "ТУПЕЙ",
  "ТУПЕЦ",
  "ТУПИК",
  "ТУРАЧ",
  "ТУРКА",
  "ТУРКИ",
  "ТУРНЕ",
  "ТУРОК",
  "ТУТОР",
  "ТУТТИ",
  "ТУФЛИ",
  "ТУФЛЯ",
  "ТУЧКА",
  "ТУШКА",
  "ТЩЕТА",
  "ТЫКВА",
  "ТЫНОК",
  "ТЫРЛО",
  "ТЫРСА",
  "ТЫЧОК",
  "ТЮБИК",
  "ТЮНИК",
  "ТЮРБЕ",
  "ТЮРБО",
  "ТЮРИК",
  "ТЮРКИ",
  "ТЮТЮН",
  "ТЮФЯК",
  "ТЮЧОК",
  "ТЯБЛО",
  "ТЯГАЧ",
  "ТЯГЛО",
  "ТЯЖБА",
  "ТЯПКА",
  "УАЗИК",
  "УБРОД",
  "УБРУС",
  "УБЫЛЬ",
  "УВРАЖ",
  "УГОДА",
  "УГОЛЬ",
  "УГОРЬ",
  "УГРЕВ",
  "УДАЛЬ",
  "УДАРЫ",
  "УДАЧА",
  "УДЕРЖ",
  "УДИЛА",
  "УДИЛО",
  "УДЭГЕ",
  "УДЭХЕ",
  "УЖАСЫ",
  "УЗБЕК",
  "УЗИНА",
  "УЗНИК",
  "УЙГУР",
  "УКЛАД",
  "УКЛЕЯ",
  "УКЛОН",
  "УКРОП",
  "УКРЮК",
  "УКСУС",
  "УЛАНЫ",
  "УЛЕМЫ",
  "УЛИКА",
  "УЛИТА",
  "УЛИЦА",
  "УЛИЧИ",
  "УЛЫБА",
  "УЛЬТА",
  "УЛЬЧИ",
  "УМБРА",
  "УМБРЫ",
  "УМИЩЕ",
  "УМНАЯ",
  "УМНИК",
  "УМНЫЙ",
  "УМОРА",
  "УНИАТ",
  "УНИКА",
  "УНИОН",
  "УНОСЫ",
  "УНТЕР",
  "УНЦИЯ",
  "УПРЕК",
  "УПЫРЬ",
  "УРАЗА",
  "УРЕМА",
  "УРИНА",
  "УРМАН",
  "УРОКИ",
  "УСИКИ",
  "УСИНА",
  "УСИЩЕ",
  "УСИЩИ",
  "УСКОК",
  "УСПЕХ",
  "УСТАВ",
  "УСТОИ",
  "УСТОЙ",
  "УСТУП",
  "УСТЬЕ",
  "УТЕРЯ",
  "УТЕХА",
  "УТИЛЬ",
  "УТИЦА",
  "УХАРЬ",
  "УХВАТ",
  "УХОЖЬ",
  "УЧЕБА",
  "УШИЦА",
  "УШКАН",
  "УШКУЙ",
  "УШНИК",
  "УЩЕРБ",
  "ФАБРА",
  "ФАВОР",
  "ФАВУС",
  "ФАГОТ",
  "ФАДОМ",
  "ФАЗАН",
  "ФАЗИС",
  "ФАКЕЛ",
  "ФАКИР",
  "ФАЛДА",
  "ФАЛЬЦ",
  "ФАНАТ",
  "ФАНТЫ",
  "ФАРАД",
  "ФАРСИ",
  "ФАСАД",
  "ФАСЕТ",
  "ФАСКА",
  "ФАСОН",
  "ФАТУМ",
  "ФАТЮЙ",
  "ФАУНА",
  "ФАЦИЯ",
  "ФАЯНС",
  "ФЕЛОН",
  "ФЕНОЛ",
  "ФЕРЗЬ",
  "ФЕРМА",
  "ФЕСКА",
  "ФЕТИШ",
  "ФЕТЮК",
  "ФИАКР",
  "ФИБРА",
  "ФИБРЫ",
  "ФИГЛИ",
  "ФИЖМЫ",
  "ФИЗИК",
  "ФИЗИЯ",
  "ФИКУС",
  "ФИЛЕЙ",
  "ФИЛЕР",
  "ФИЛИН",
  "ФИЛЬМ",
  "ФИЛЬЦ",
  "ФИМОЗ",
  "ФИНАЛ",
  "ФИНИК",
  "ФИНИШ",
  "ФИНКА",
  "ФИННА",
  "ФИННЫ",
  "ФИОРД",
  "ФИРМА",
  "ФИТИН",
  "ФИШКА",
  "ФЛАНГ",
  "ФЛАНК",
  "ФЛЕЙТ",
  "ФЛЕЙЦ",
  "ФЛЕШЬ",
  "ФЛИНТ",
  "ФЛИРТ",
  "ФЛОКС",
  "ФЛОРА",
  "ФЛЮИД",
  "ФЛЯГА",
  "ФОБИЯ",
  "ФОКУС",
  "ФОЛИО",
  "ФОМКА",
  "ФОНДЫ",
  "ФОРМА",
  "ФОРМЫ",
  "ФОРТЕ",
  "ФОРУМ",
  "ФОСКА",
  "ФОТОН",
  "ФОФАН",
  "ФРАЕР",
  "ФРАЗА",
  "ФРАНК",
  "ФРАНТ",
  "ФРАХТ",
  "ФРЕЗА",
  "ФРЕНЧ",
  "ФРИЗЫ",
  "ФРИЦЫ",
  "ФРОНТ",
  "ФРУКТ",
  "ФУГАС",
  "ФУЖЕР",
  "ФУЗЕЯ",
  "ФУКУС",
  "ФУЛЯР",
  "ФУРАЖ",
  "ФУРИЯ",
  "ФУРМА",
  "ФУРОР",
  "ФУТЕР",
  "ФУТОР",
  "ФУФЛО",
  "ФУЭТЕ",
  "ФЬОРД",
  "ФЮРЕР",
  "ХАБАР",
  "ХАДЖИ",
  "ХАДИС",
  "ХАЗАР",
  "ХАЙЛО",
  "ХАКАС",
  "ХАКЕР",
  "ХАЛАТ",
  "ХАЛВА",
  "ХАЛДА",
  "ХАЛИФ",
  "ХАМИТ",
  "ХАМКА",
  "ХАМСА",
  "ХАМЬЕ",
  "ХАНЖА",
  "ХАНТЫ",
  "ХАНША",
  "ХАПУН",
  "ХАРОН",
  "ХАРЧИ",
  "ХАРЧО",
  "ХАСИД",
  "ХАТКА",
  "ХАШИШ",
  "ХВАЛА",
  "ХВОРЬ",
  "ХВОСТ",
  "ХВОЩИ",
  "ХЕДЕР",
  "ХЕДИВ",
  "ХЕРЕС",
  "ХЕРИК",
  "ХЕТТЫ",
  "ХИАЗМ",
  "ХИЛАЯ",
  "ХИЛЫЙ",
  "ХИЛЯК",
  "ХИМИК",
  "ХИМИЯ",
  "ХИНДИ",
  "ХИНДУ",
  "ХИНИН",
  "ХИППИ",
  "ХИТИН",
  "ХИТОН",
  "ХИ-ХИ",
  "ХЛЕСТ",
  "ХЛУПЬ",
  "ХЛЫСТ",
  "ХЛЮСТ",
  "ХЛЯБЬ",
  "ХМАРА",
  "ХМАРЬ",
  "ХМЕЛЬ",
  "ХМУРЬ",
  "ХОББИ",
  "ХОБОТ",
  "ХОДОК",
  "ХОДУН",
  "ХОЛКА",
  "ХОЛОД",
  "ХОЛОП",
  "ХОЛСТ",
  "ХОЛУЙ",
  "ХОМУТ",
  "ХОМЯК",
  "ХОРАЛ",
  "ХОРДА",
  "ХОРЕЙ",
  "ХОРЕК",
  "ХОРЕЯ",
  "ХОРТЫ",
  "ХОХМА",
  "ХОХОЛ",
  "ХОХОТ",
  "ХРИПЫ",
  "ХРУСТ",
  "ХРЯПА",
  "ХРЯСК",
  "ХРЯСТ",
  "ХУДОЕ",
  "ХУННУ",
  "ХУНТА",
  "ХУРАЛ",
  "ХУРМА",
  "ХУТОР",
  "ЦАДИК",
  "ЦАНГА",
  "ЦАПКА",
  "ЦАПЛЯ",
  "ЦАПУН",
  "ЦАПФА",
  "ЦАРЕК",
  "ЦАЦКА",
  "ЦЕВКА",
  "ЦЕВЬЕ",
  "ЦЕДРА",
  "ЦЕЗИЙ",
  "ЦЕЛИК",
  "ЦЕЛОЕ",
  "ЦЕНТР",
  "ЦЕХИН",
  "ЦИКЛЯ",
  "ЦИНГА",
  "ЦИНИК",
  "ЦИСТА",
  "ЦИТРА",
  "ЦИФРА",
  "ЦИФРЫ",
  "ЦОКОТ",
  "ЦУКАТ",
  "ЦУЦИК",
  "ЦЫБИК",
  "ЦЫГАН",
  "ЦЫПКА",
  "ЦЫПКИ",
  "ЧАБАН",
  "ЧАБЕР",
  "ЧАДРА",
  "ЧАЙКА",
  "ЧАКАН",
  "ЧАЛАЯ",
  "ЧАЛКА",
  "ЧАЛМА",
  "ЧАЛЫЙ",
  "ЧАРКА",
  "ЧАСИК",
  "ЧАСОК",
  "ЧАСТЬ",
  "ЧАШКА",
  "ЧЕБАК",
  "ЧЕБОТ",
  "ЧЕКАН",
  "ЧЕЛКА",
  "ЧЕМЕР",
  "ЧЕПАН",
  "ЧЕПЕЦ",
  "ЧЕРВА",
  "ЧЕРВИ",
  "ЧЕРВЫ",
  "ЧЕРВЬ",
  "ЧЕРЕД",
  "ЧЕРЕЗ",
  "ЧЕРЕН",
  "ЧЕРЕП",
  "ЧЕРНИ",
  "ЧЕРНЬ",
  "ЧЕРТА",
  "ЧЕРТЫ",
  "ЧЕСКА",
  "ЧЕСТЬ",
  "ЧЕТКА",
  "ЧЕТКИ",
  "ЧЕХОЛ",
  "ЧЕЧЕН",
  "ЧЕЧЕТ",
  "ЧЕШКА",
  "ЧЕШУЯ",
  "ЧИБИС",
  "ЧИЖИК",
  "ЧИЛИМ",
  "ЧИНАР",
  "ЧИНКА",
  "ЧИПСЫ",
  "ЧИРЕЙ",
  "ЧИРИК",
  "ЧИРКИ",
  "ЧИРОК",
  "ЧИРУС",
  "ЧИРЯК",
  "ЧИСЛО",
  "ЧИТКА",
  "ЧЛЕНЫ",
  "ЧОМГА",
  "ЧРЕВО",
  "ЧРЕДА",
  "ЧТИВО",
  "ЧТИЦА",
  "ЧУБИК",
  "ЧУБУК",
  "ЧУВАЛ",
  "ЧУВАШ",
  "ЧУВЯК",
  "ЧУГУН",
  "ЧУДАК",
  "ЧУДИК",
  "ЧУЖАК",
  "ЧУЖАЯ",
  "ЧУЖОЕ",
  "ЧУЖОЙ",
  "ЧУЙКА",
  "ЧУКЧА",
  "ЧУКЧИ",
  "ЧУЛАН",
  "ЧУЛКИ",
  "ЧУЛОК",
  "ЧУМАК",
  "ЧУРЕК",
  "ЧУРКА",
  "ЧУТЬЕ",
  "ЧУХНА",
  "ЧУШКА",
  "ЧУШКИ",
  "ШАБАТ",
  "ШАБЕР",
  "ШАБЛИ",
  "ШАБОТ",
  "ШАБУР",
  "ШАВКА",
  "ШАЖОК",
  "ШАЙБА",
  "ШАЙКА",
  "ШАКАЛ",
  "ШАЛАШ",
  "ШАЛОН",
  "ШАЛУН",
  "ШАМАН",
  "ШАМОТ",
  "ШАНЕЦ",
  "ШАНКР",
  "ШАПКА",
  "ШАРИК",
  "ШАСЛА",
  "ШАССЕ",
  "ШАССИ",
  "ШАТЕН",
  "ШАТЕР",
  "ШАТИЯ",
  "ШАТУН",
  "ШАФЕР",
  "ШАХТА",
  "ШАШКА",
  "ШАШКИ",
  "ШАШНИ",
  "ШВАБЫ",
  "ШВАЛЬ",
  "ШВАНК",
  "ШВЕДЫ",
  "ШВЕРТ",
  "ШЕВРО",
  "ШЕЙКА",
  "ШЕЛЕП",
  "ШЕЛКА",
  "ШЕЛОМ",
  "ШЕЛЬФ",
  "ШЕМАЯ",
  "ШЕПОТ",
  "ШЕРИФ",
  "ШЕРПА",
  "ШЕРПЫ",
  "ШИБЕР",
  "ШИЗИК",
  "ШИИЗМ",
  "ШИММИ",
  "ШИНОК",
  "ШИПОК",
  "ШИПУН",
  "ШИРМА",
  "ШИРМЫ",
  "ШИТВО",
  "ШИТИК",
  "ШИТЬЕ",
  "ШИФЕР",
  "ШИФОН",
  "ШИХТА",
  "ШИШАК",
  "ШИШКА",
  "ШКАЛА",
  "ШКВАЛ",
  "ШКЕРТ",
  "ШКОДА",
  "ШКОЛА",
  "ШКУРА",
  "ШЛАНГ",
  "ШЛЕЙФ",
  "ШЛИЦА",
  "ШЛИЦЫ",
  "ШЛЮХА",
  "ШЛЯПА",
  "ШМЕЛЬ",
  "ШМОЛЬ",
  "ШНАПС",
  "ШНЕКА",
  "ШНЯВА",
  "ШНЯКА",
  "ШОРНЯ",
  "ШОРОХ",
  "ШОРТЫ",
  "ШОССЕ",
  "ШОФЕР",
  "ШПАГА",
  "ШПАЛА",
  "ШПАЛЫ",
  "ШПАНА",
  "ШПАТЫ",
  "ШПИЛЬ",
  "ШПИОН",
  "ШПОРА",
  "ШПРИЦ",
  "ШПРОТ",
  "ШПУЛЯ",
  "ШПУНТ",
  "ШПЫНЬ",
  "ШРИМС",
  "ШРИФТ",
  "ШТАМБ",
  "ШТАММ",
  "ШТАМП",
  "ШТАНЫ",
  "ШТАТЫ",
  "ШТЕЙН",
  "ШТИЛЬ",
  "ШТИФТ",
  "ШТОРА",
  "ШТОРМ",
  "ШТОСС",
  "ШТРАБ",
  "ШТРАФ",
  "ШТРЕК",
  "ШТРИХ",
  "ШТУКА",
  "ШТУРМ",
  "ШТЫКА",
  "ШТЫКИ",
  "ШТЫРЬ",
  "ШУБКА",
  "ШУГАЙ",
  "ШУЙЦА",
  "ШУЛЕР",
  "ШУМОК",
  "ШУРИН",
  "ШУРПА",
  "ШУРУП",
  "ШУРЯК",
  "ШУТКА",
  "ШУШУН",
  "ШХЕРЫ",
  "ШХУНА",
  "ЩЕБЕТ",
  "ЩЕГОЛ",
  "ЩЕКОТ",
  "ЩЕЛКА",
  "ЩЕЛОК",
  "ЩЕНОК",
  "ЩЕПКА",
  "ЩЕПЬЕ",
  "ЩЕРБА",
  "ЩЕТКА",
  "ЩЕЧКА",
  "ЩИПЕЦ",
  "ЩИПКА",
  "ЩИПОК",
  "ЩИПЦЫ",
  "ЩИТИК",
  "ЩИТНИ",
  "ЩИТОК",
  "ЩИШКИ",
  "ЩУПИК",
  "ЩУРКА",
  "ЩУЧКА",
  "ЭВЕНК",
  "ЭВЕНЫ",
  "ЭГИДА",
  "ЭГРЕТ",
  "ЭДИКТ",
  "ЭКЗОТ",
  "ЭКЛЕР",
  "ЭКРАН",
  "ЭЛИТА",
  "ЭЛЛИН",
  "ЭМАЛЬ",
  "ЭММЕР",
  "ЭНЗИМ",
  "ЭОЛИТ",
  "ЭПИКА",
  "ЭПОНЖ",
  "ЭПОХА",
  "ЭРАТА",
  "ЭРАТО",
  "ЭРЗАЦ",
  "ЭРКЕР",
  "ЭСКИЗ",
  "ЭСПРИ",
  "ЭСТЕТ",
  "ЭТИКА",
  "ЭТНОС",
  "ЭФИОП",
  "ЭШАРП",
  "ЮГРИЧ",
  "ЮГУРТ",
  "ЮДОЛЬ",
  "ЮЖАНЕ",
  "ЮЗИСТ",
  "ЮКОЛА",
  "ЮНАКИ",
  "ЮНИОР",
  "ЮНИЦА",
  "ЮНКЕР",
  "ЮНКОР",
  "ЮННАТ",
  "ЮНОША",
  "ЮРАГА",
  "ЮРИСТ",
  "ЮФЕРС",
  "ЯБЕДА",
  "ЯГЕЛЬ",
  "ЯГОДА",
  "ЯГУАР",
  "ЯИЧКО",
  "ЯИШНЯ",
  "ЯКОРЬ",
  "ЯКУТЫ",
  "ЯМИНА",
  "ЯМИЩА",
  "ЯМЩИК",
  "ЯРИЦА",
  "ЯРЛЫК",
  "ЯРУГА",
  "ЯРЫГА",
  "ЯСЕНЬ",
  "ЯСПИС",
  "ЯСТВА",
  "ЯСТВО",
  "ЯСТЫК",
  "ЯСЫРЬ",
  "ЯТОВЬ",
  "ЯХОНТ",
  "ЯЩЕРЫ"
];

export default fiveCharWords;